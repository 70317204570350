// noinspection UnnecessaryLocalVariableJS

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { successNotification } from "../../notifications";
import { codeRegexPattern, emailRegex } from "../../../_helpers/staticFields";

import { ImportModalMarkup } from "./ImportModalMarkup";
// import brand from "../../../../_metronic/_assets/js/layout/base/brand";

export const ImportModalBody = ({
                                  importItems,
                                  updateDisable,
                                  createMethod,
                                  name,
                                  title,
                                  entityCode,
                                  updatedMethod,
                                  setFailedImport,
                                  pause,
                                  stop,
                                  setHiddenEdit,
                                  setHiddenAdded,
                                  setHiddenFailed,
                                  setHiddenShowEdit,
                                }) => {
  const { entityForEdit, fieldsDictionary } = useSelector(
      state => ({
        entityForEdit: state.entities?.entityForEdit || {},
        fieldsDictionary: state.fields?.fieldsDictionary || {}
      }),
      shallowEqual
  );

  const dispatch = useDispatch();

  const [added, setAdded] = useState(0);
  const [failed, setFailed] = useState(0);
  const [edited, setEdited] = useState(0);
  const [pending, setPending] = useState(importItems?.length || 0);
  const [showEdit, setShowEdit] = useState(false);

  const [importStatus, setImportStatus] = useState({});
  const [messageStatus, setMessageStatus] = useState({});
  const validationErrorStatus = {};
  const [validationStatus, setValidationStatus] = useState({});
  const [workflowResponse, setWorkflowResponse] = useState([]);
  const [requiredFieldsForRecords, setRequiredFieldsForRecords] = useState([]);
  const [isRequiredFieldsChecked, setIsRequiredFieldsChecked] = useState(false);
  const [resumeImportState, setResumeImportState] = useState(null);
  const isProcessing = useRef(false);

  const pauseDataImport = useRef();
  const stopDataImport = useRef();

  pauseDataImport.current = pause;
  stopDataImport.current = stop;

  useEffect(() => {
    if (!pause && resumeImportState) {
      createRecursiveFunction(...Object?.values(resumeImportState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause]);

  useEffect(() => {
    if (entityForEdit?.fields?.length && !isRequiredFieldsChecked) {
      let entityFields = entityForEdit?.fields || [];
      let tempRequiredFieldsForRecords = [];

      entityFields.forEach(code => {
        if (fieldsDictionary[code]?.isRequired === true) {
          tempRequiredFieldsForRecords.push(fieldsDictionary[code]);
        }
      });

      setRequiredFieldsForRecords(tempRequiredFieldsForRecords);
      setIsRequiredFieldsChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityForEdit, fieldsDictionary]);

  useEffect(() => {
    if (
        (entityForEdit?.fields?.length && isRequiredFieldsChecked) ||
        !entityForEdit?.fields?.length
    ) {
      if (importItems?.length) {
        if (
            importItems.some(
                item => item?.importType?.toString()?.toLowerCase() === "edit"
            )
        ) {
          setShowEdit(true);
        }
        createRecursiveFunction();
      }
      setAdded(0);
      setFailed(0);
      setEdited(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importItems, isRequiredFieldsChecked]);

  useEffect(() => {
    setHiddenAdded(added);
    setHiddenFailed(failed);
    setHiddenEdit(edited);
    setHiddenShowEdit(showEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added, failed, edited, showEdit]);

  const dataAdded = (errorStatus, length) => {
    updateDisable(1);
    successNotification("Operation performed successfully");
    // if (Object.keys(errorStatus || {})?.length === 0) {
    //   successNotification(`All ${title} Imported successfully.`);
    // }
  };

  const dynamicRequiredFieldsCondition = item => {
    let requiredCounter = 0;

    requiredFieldsForRecords.forEach(field => {
      if (String(item[`fields_${field?.code}`] || "").trim().length)
        requiredCounter++;
    });

    return Boolean(requiredCounter === requiredFieldsForRecords.length);
  };

  const requiredChecks = count => {
    if (name === "user") {
      if (
          String(
              importItems[count]?.name || importItems[count]?.Name || ""
          )?.trim()?.length &&
          String(
              importItems[count]?.code || importItems[count]?.Code || ""
          )?.trim()?.length &&
          String(importItems[count]?.emailAddress || "")?.trim()?.length &&
          String(importItems[count]?.groupCodes || "")?.trim()?.length
      ) {
        if (checkValidation(count)) return true;
      } else if (importItems[count]?.emailAddress?.trim()?.length) {
        checkValidation(count);
      } else if (
          importItems?.[count]?.importType?.toString()?.toLowerCase() === "edit"
      ) {
        return true;
      }
    } else if (name === "record") {
      if (
          importItems?.[count]?.importType?.toString()?.toLowerCase() === "edit"
      ) {
        if (
            String(
                importItems[count]?.code || importItems[count]?.Code || ""
            ).trim().length
        ) {
          return true;
        }
      } else {
        if (
            String(
                importItems[count]?.name || importItems[count]?.Name || ""
            ).trim().length &&
            String(
                importItems[count]?.code || importItems[count]?.Code || ""
            ).trim().length &&
            dynamicRequiredFieldsCondition(importItems[count])
        ) {
          return true;
        }
      }
    } else if (name === "field") {
      if (
          importItems?.[count]?.importType?.toString()?.toLowerCase() === "edit"
      ) {
        if (
            String(
                importItems[count]?.code || importItems[count]?.Code || ""
            ).trim().length
        ) {
          return true;
        }
      } else {
        if (
            String(
                importItems[count]?.name || importItems[count]?.Name || ""
            ).trim().length &&
            String(
                importItems[count]?.code || importItems[count]?.Code || ""
            ).trim().length &&
            String(importItems[count]?.dataType || "0").trim().length
        ) {
          return true;
        }
      }
    } else if (name !== "workflow") {
      if (
          String(
              importItems[count]?.name || importItems[count]?.Name || ""
          ).trim().length &&
          String(
              importItems[count]?.code || importItems[count]?.Code || ""
          ).trim().length
      ) {
        if (checkValidation(count)) return true;
      }
    } else return true;
  };

  const checkValidation = count => {
    if (name === "user") {
      if (
          importItems[count]?.heirarchyCodes?.split(",").length !==
          importItems[count]?.heirarchyAssignedCodes?.split(",").length
      ) {
        validationErrorStatus[count] = "Heirarchy Codes are Invalid.";
        setValidationStatus(validationErrorStatus);
        return false;
      }
      if (!emailRegex.test(importItems[count].emailAddress)) {
        validationErrorStatus[count] = "Email is Invalid.";
        setValidationStatus(validationErrorStatus);
        return false;
      } else return true;
    } else if (
        name === "group" &&
        importItems[count].moduleCode &&
        importItems[count].permissions
    ) {
      if (
          importItems[count].moduleCode.split(",").length !==
          importItems[count].permissions.split(",").length
      ) {
        validationErrorStatus[count] = "Modules are Invalid.";
        setValidationStatus(validationErrorStatus);
      } else return true;
    } else {
      return true;
    }
  };

  const validationError = count => {
    let arr = [];
    if (
        !String(importItems[count].name || importItems[count].Name || "").trim()
            .length
    )
      arr.push("Name");
    if (
        !String(importItems[count].code || importItems[count].Code || "").trim()
            .length
    )
      arr.push("Code");
    if (name === "user") {
      if (!importItems[count]?.emailAddress?.trim().length) {
        arr.push("Email");
      }
      if (!String(importItems[count].groupCodes || "").trim().length) {
        arr.push("Group Code");
      }
    }
    if (name === "record") {
      requiredFieldsForRecords.forEach(field => {
        if (!importItems[count][`fields_${field?.code}`]) {
          arr.push(field?.name);
        }
      });
    }
    if (name === "field") {
      if (!String(importItems[count]?.dataType || 0)?.trim().length) {
        arr.push("Data type");
      }
    }
    return arr;
  };

  const concatenateType = name => `${name}Type`;

  const selectAndMultiSelectValue = (code, value) => {
    if (fieldsDictionary?.[code]?.dataType === 2) {
      value = removeSpaceFromValue(value);
    } else if (fieldsDictionary?.[code]?.dataType === 3) {
      if (value?.includes(",")) {
        if (value?.lastIndexOf(",") === value.length - 1) {
          let lastIndex = value?.lastIndexOf(",");
          let replaceValue = "";
          let tempValue =
              value?.substring(0, lastIndex) +
              replaceValue +
              value?.substring(lastIndex + 1);
          value = tempValue;
        }
        value = value?.replace(/,/g, "!").trim();
      }
    }
    return value;
  };

  const removeSpaceFromValue = value => {
    value = String(value || "").replace(/\s/g, "");
    return value;
  };

  const createRecursiveFunction = (
      count = 0,
      importItemStatus = {},
      errorStatus = {}
  ) => {
    // Check if paused or stopped, and save the current state
    if (pauseDataImport.current || stopDataImport.current) {
      setResumeImportState({ count, importItemStatus, errorStatus });
      return; // Exit the function to prevent further execution
    }

    // Ensure no overlapping calls by using isProcessing flag
    if (isProcessing.current) return;
    isProcessing.current = true;

    // Base condition to exit the recursion
    if (count >= importItems?.length) {
      isProcessing.current = false; // Reset the processing flag
      setPending(0); // Ensure pending is explicitly set to zero
      return dataAdded(errorStatus, importItems?.length);
    }

    // Perform required checks and processing for the current item
    if (requiredChecks(count)) {
      if (name === "field") {
        if (importItems[count].subFields) {
          var subFields = importItems[count].subFields
              .split(",")
              .map(function(val) {
                return val.trim();
              });
          importItems[count].subFields = subFields;
        }
        if (importItems[count].tags) {
          var tags = importItems[count].tags.split(",").map(function(val) {
            return val.trim();
          });
          importItems[count].tags = tags;
        }
        if (!importItems[count].group) {
          importItems[count].group = "";
        }
        if (!importItems[count].header) {
          importItems[count].header = "";
        }
        if (!importItems[count].minLength) {
          importItems[count].minLength = null;
        }
        if (!importItems[count].maxLength) {
          importItems[count].maxLength = "200";
        }
        if (
            importItems[count].supportFuzzySearch &&
            typeof importItems[count].supportFuzzySearch === "string"
        ) {
          importItems[count].supportFuzzySearch = JSON.parse(
              importItems[count].supportFuzzySearch.toLowerCase()
          );
        }
        if (
            importItems[count].isRequired &&
            typeof importItems[count].isRequired === "string"
        ) {
          importItems[count].isRequired = JSON.parse(
              importItems[count].isRequired.toLowerCase()
          );
        }
        if (
            importItems[count].isReadOnly &&
            typeof importItems[count].isReadOnly === "string"
        ) {
          importItems[count].isReadOnly = JSON.parse(
              importItems[count].isReadOnly.toLowerCase()
          );
        }
        if (
            importItems[count].isHidden &&
            typeof importItems[count].isHidden === "string"
        ) {
          importItems[count].isHidden = JSON.parse(
              importItems[count].isHidden.toLowerCase()
          );
        }
        if (importItems[count]?.products) {
          let products = importItems[count]?.products
              ?.split(",")
              .map(function(val) {
                return val.trim();
              });
          importItems[count].products = products;
        }
      }

      if (name === "entity") {
        if (importItems[count].fields) {
          var fields = importItems[count].fields.split(",").map(function(val) {
            return val.trim();
          });
          importItems[count].fields = fields;
        } else {
          importItems[count].fields = [];
        }
      }

      if (name === "user") {
        if (importItems[count].groupCodes) {
          var groupCodes = importItems[count]?.groupCodes
              ?.split(",")
              .map(function(val) {
                return val.trim();
              });
          importItems[count].groupCodes = groupCodes;
        }

        if (
            importItems[count].heirarchyCodes &&
            importItems[count].heirarchyAssignedCodes
        ) {
          let heirarchyCodes = importItems[count].heirarchyCodes
              .split(",")
              .map(function(val) {
                return val.trim();
              });
          let heirarchyAssignedCodes = importItems[count].heirarchyAssignedCodes
              .split(",")
              .map(function(val) {
                return val.trim();
              });
          let hierarchies = [];
          for (let i = 0; i < heirarchyCodes.length; i++) {
            let heirarchy = {
              code: heirarchyCodes[i],
              assignedCodes: heirarchyAssignedCodes[i]
                  .split("/")
                  .map(function(val) {
                    return val.trim();
                  })
            };
            hierarchies.push(heirarchy);
          }
          importItems[count].hierarchies = hierarchies;
        }
      }

      if (
          name === "group" &&
          importItems[count].moduleCode &&
          importItems[count].permissions
      ) {
        let moduleCodes = importItems[count].moduleCode
            .split(",")
            .map(function(val) {
              return val.trim();
            });
        let modulePermissions = importItems[count].permissions
            .split(",")
            .map(function(val) {
              return val.trim();
            });
        var moduleArray = [];
        for (let i = 0; i < moduleCodes.length; i++) {
          var modules = {
            moduleCode: moduleCodes[i],
            permissions: modulePermissions[i].split("/").map(function(val) {
              return val.trim();
            })
          };
          moduleArray.push(modules);
        }
        importItems[count].modulePermissions = moduleArray;
      }

      if (name === "record") {
        let updatedCurrentItem = {};
        let fields = {};
        for (let [key, value] of Object.entries(importItems[count])) {
          if (key.includes("_")) {
            var splittedKey = key.split("_");

            //Select and Multiselect values conversion here ...
            value = selectAndMultiSelectValue(splittedKey?.[1], value);

            if (typeof value === "string")
              fields[splittedKey[1]] = value.trim();
            else fields[splittedKey[1]] = value;
          } else {
            //Remove extra space from code values conversion here ...
            // if (key === 'code') {
            //   value = removeSpaceFromValue(value)
            // }
            if (typeof value === "string")
              updatedCurrentItem[key] = value.trim();
            else updatedCurrentItem[key] = value;
          }

          if (key === "importType" && value === "edit") {
            updatedCurrentItem["type"] = 0;
          }
        }
        updatedCurrentItem.fields = fields;
        importItems[count] = updatedCurrentItem;
      }

      if (name === "workflow") {
        let updatedCurrentItem = {};
        let fields = {};
        for (const [key, value] of Object.entries(importItems[count])) {
          if (key.includes("_")) {
            var splitKey = key.split("_");
            if (splitKey[1] === "RRLFLD0041") {
              fields[splitKey[1]] = moment(value.trim(), "DD/MM/YYYY").format();
            } else if (typeof value === "string") {
              fields[splitKey[1]] = value.trim();
            } else fields[splitKey[1]] = value;
          } else {
            if (typeof value === "string")
              updatedCurrentItem[key] = value.trim();
            else updatedCurrentItem[key] = value;
            if (key === "hierarchy") {
              fields[key] = typeof value === "string" ? value?.trim() : value;
            }
          }
        }
        updatedCurrentItem.fields = fields;
        importItems[count] = updatedCurrentItem;
      } else {
        if (importItems?.[count]?.code) {
          importItems[count].code = String(importItems[count]?.code)?.replace(
              codeRegexPattern,
              ""
          );
        }
      }

      Object.keys(importItems[count]).forEach(val => {
        if (typeof importItems[count][val] === "string")
          importItems[count][val] = importItems[count][val].trim();
      });

      let params = { entityCode, [name]: importItems[count] };
      if (name === "workflow") params = importItems[count];

      let type = concatenateType(name);
      dispatch(
          importItems?.[count]?.importType?.toString()?.toLowerCase() === "edit"
              ? updatedMethod({ ...params, [type]: 0 })
              : createMethod(params)
      ).then((res) => {
        // Handle success or failure of the dispatch call
        if (res && res.status === 200) {
          if (
              importItems?.[count]?.importType?.toString()?.toLowerCase() === "edit"
          ) {
            importItemStatus[count] = "Edited";
            setEdited((prev) => Math.max(0, prev + 1));
          } else {
            importItemStatus[count] = "Added";
            setAdded((prev) => Math.max(0, prev + 1));
          }
        } else {
          importItemStatus[count] = "Failed";
          setFailed((prev) => Math.max(0, prev + 1));
          errorStatus[count] =
              res?.data?.message ||
              res?.data?.title ||
              "Unexpected error occurred";
          setMessageStatus(errorStatus);
        }

        // Update the state
        setImportStatus(importItemStatus);

        if (name === "workflow") {
          setWorkflowResponse(prevArray => [...prevArray, res?.data || {}]);
        }

        // Decrement pending safely
        setPending((prev) => Math.max(0, prev - 1));

        // Recursive call to process the next item
        isProcessing.current = false; // Reset processing flag
        createRecursiveFunction(++count, importItemStatus, errorStatus);
      })
          .catch(() => {
            // Handle errors gracefully
            setFailed((prev) => Math.max(0, prev + 1));
            setPending((prev) => Math.max(0, prev - 1));
            isProcessing.current = false;
            createRecursiveFunction(++count, importItemStatus, errorStatus);
          });
    } else {
      // Handle invalid cases and move to the next item
      setTimeout(() => {
        setFailedImport((prev) => [...prev, importItems[count]]);
        importItemStatus[count] = "Failed";

        setFailed((prev) => Math.max(0, prev + 1));
        setPending((prev) => Math.max(0, prev - 1));

        if (
            importItems?.[count]?.importType?.toString()?.toLowerCase() !== "edit"
        ) {
          let arrMessage = validationError(count);
          errorStatus[count] = arrMessage;
          setMessageStatus(errorStatus);
        }

        isProcessing.current = false; // Reset processing flag
        createRecursiveFunction(++count, importItemStatus, errorStatus);
      }, 100);
    }

  };

  return (
      <ImportModalMarkup
          name={name}
          added={added}
          failed={failed}
          edited={edited}
          pending={pending}
          setPending={setPending}
          showEdit={showEdit}
          importItems={importItems}
          importStatus={importStatus}
          messageStatus={messageStatus}
          validationStatus={validationStatus}
          workflowResponse={workflowResponse}
          pause={pauseDataImport.current}
          stop={stopDataImport.current}
      />
  );
};
