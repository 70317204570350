import { inputTypesDictionary } from "./staticFields";
import { v4 as uuidv4 } from "uuid";

//Fields conditions according to DataTypes
export const dataTypeConditions = fields => {
  let dataTypesFieldConditions = [];
  let staticDefaultFieldsConditions = [];

  Object.keys(inputTypesDictionary).forEach((key, i) => {
    let mergedOutcomes = [];

    fields.forEach((item, index) => {
      if (inputTypesDictionary[key]?.hiddenFields?.includes(item.code)) {
        mergedOutcomes.push({
          code: item.code,
          isHidden: true,
          hidden: true,
          type: 2,
          isRequired: false,
          priority: index + 1,
          id: uuidv4()
        });
      } else {
        if ((key === "2" || key === "3") && item.code === "masterDataType") {
          mergedOutcomes.push({
            code: item.code,
            isHidden: false,
            isRequired: true,
            hidden: false,
            type: 2,
            priority: index + 1,
            id: uuidv4()
          });
        } else {
          mergedOutcomes.push({
            code: item.code,
            isHidden: false,
            hidden: false,
            type: 2,
            priority: index + 1,
            id: uuidv4()
          });
        }
      }
    });

    dataTypesFieldConditions.push({
      name: inputTypesDictionary[key].displayType,
      code: key,
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"dataType"},${key}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: mergedOutcomes
    });
    if (!["5", "6", "11", "14"].includes(key)) {
      staticDefaultFieldsConditions.push({
        name: "Static" + key,
        code: "static" + key,
        id: uuidv4(),
        expression: {
          representations: [
            {
              representationValue: `{"and":[{"==":[{"var":"defaultValueType"},0]},{"==":[{"var":"dataType"},${key}]}]}`,
              representationType: 4
            }
          ]
        },
        outcomes: [
          {
            code: "defaultValue",
            name: "Default Value",
            description: "Select Master Data Type for Select and Multi Select",
            dataType: Number(key),
            isHidden: false,
            hidden: false,
            type: 2,
            priority: i + 1,
            masterDataType: null
          }
        ]
      });
    }
  });

  let fieldDefaultFieldCondition = {
    name: "Field",
    code: "field",
    id: uuidv4(),
    expression: {
      representations: [
        {
          representationValue: `{"==":[{"var":"defaultValueType"},${1}]}`,
          representationType: 4
        }
      ]
    },
    outcomes: [
      {
        code: "defaultValue",
        name: "Default Value Field",
        dataType: 2,
        isHidden: false,
        hidden: false,
        type: 2,
        priority: 19,
        masterDataType: "fields"
      }
    ]
  };

  return [
    ...dataTypesFieldConditions,
    ...staticDefaultFieldsConditions,
    fieldDefaultFieldCondition
  ];
};

export const notificationConditions = () => {
  return [
    {
      name: "Product notification",
      code: "productNotification",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"notificationType"},1]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "productCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "stageCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 2,
          id: uuidv4()
        }
      ]
    },

    {
      name: "Escalation event",
      code: "escalationEvent",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"type"},7]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "escalationCodes",
          // dataType: 0,
          isHidden: false,
          isRequired: false,
          hidden: false,
          type: 2,
          priority: 3,
          id: uuidv4()
        }
      ]
    },
    {
      name: "OTP event",
      code: "otpEvent",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"type"},8]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "length",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 4,
          id: uuidv4()
        },
        {
          code: "passwordType",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "retriesCount",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 6,
          id: uuidv4()
        },
        {
          code: "resentAfterDuration",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 7,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Authentication Levels",
      code: "authenticationLevels",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"and":[{"==":[{"var":"notificationType"},1]},{"==":[{"var":"eventType"},12]}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "levels",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 8,
          id: uuidv4()
        }
      ]
    }
  ];
};

export const notificationTemplateConditions = () => {
  return [
    {
      name: "Grid Field",
      code: "gridField",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"fieldType"},0]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "gridCode",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    }
  ];
};

export const scheduleInterviewConditions = () => {
  return [
    {
      name: "Date",
      code: "date",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"var":"dateOnly"}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "agentCode",
          disabled: false,
          isRequired: true,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Agent",
      code: "agent",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"var":"agentCode"}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "startTime",
          disabled: false,
          isRequired: true,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    }
  ];
};

export const createDiscrepancyConditions = () => {
  return [
    {
      name: "Discrepancy Type",
      code: "discrepancyType",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"discrepancyType"},0]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "additionalInformation",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 2,
          id: uuidv4()
        },
        {
          code: "documentType",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 2,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Discrepancy Type",
      code: "discrepancyType",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"discrepancyType"},1]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "documentType",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 3,
          id: uuidv4()
        },
        {
          code: "fieldCodes",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 4,
          id: uuidv4()
        },
        {
          code: "isGrid",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 4,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid",
      code: "isGrid",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"isGrid"},true]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 6,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid",
      code: "isGrid",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"isGrid"},false]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 7,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 8,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid",
      code: "isGrid",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"and":[{"==":[{"var":"isGrid"},false]},{"==":[{"var":"discrepancyType"},1]}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Checklist Document",
      code: "checklistDocument",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"documentType"},0]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "additionalInformation",
          isHidden: false,
          isRequired: true,
          hidden: false,
          masterDataType: "additionalInformationCodes",
          name: "CheckList",
          type: 2,
          priority: 2,
          id: uuidv4()
        },
        {
          code: "fieldCodes",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Additional Document",
      code: "additionalDocument",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"documentType"},1]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "additionalInformation",
          isHidden: false,
          isRequired: true,
          hidden: false,
          name: "Additional Document",
          masterDataType: "DocumentGroups",
          type: 2,
          priority: 2,
          id: uuidv4()
        },
        {
          code: "fieldCodes",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    }
  ];
};

export const createAdviceConditions = () => {
  return [
    {
      name: "Field Type",
      code: "fieldType",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"type"},0]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "additionalInformation",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 2,
          id: uuidv4()
        },
        {
          code: "documentType",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 2,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Document Type",
      code: "documentType",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"type"},1]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "documentType",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 3,
          id: uuidv4()
        },
        {
          code: "fieldCodes",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "isGrid",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 6,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Both Type",
      code: "bothType",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"type"},2]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "documentType",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 7,
          id: uuidv4()
        },
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 8,
          id: uuidv4()
        },
        {
          code: "isGrid",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 6,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid",
      code: "isGrid",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"isGrid"},true]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "type",
          isHidden: false,
          isRequired: true,
          hidden: false,
          disabled: true,
          type: 2,
          priority: 11,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid",
      code: "isGrid",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"isGrid"},false]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "type",
          isHidden: false,
          isRequired: true,
          hidden: false,
          disabled: false,
          type: 2,
          priority: 11,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 6,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid Field",
      code: "isGridField",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"and":[{"==":[{"var":"isGrid"},true]},{"==":[{"var":"type"},0]}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 6,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Field",
      code: "isField",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"and":[{"==":[{"var":"isGrid"},false]},{"==":[{"var":"type"},0]}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 6,
          id: uuidv4()
        },
        {
          code: "additionalInformation",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 4,
          id: uuidv4()
        },
        {
          code: "isGrid",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid Document",
      code: "isGridField",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"and":[{"==":[{"var":"isGrid"},false]},{"==":[{"var":"type"},1]}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 6,
          id: uuidv4()
        },
        {
          code: "documentType",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 4,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Is Grid Both",
      code: "isGridBoth",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"and":[{"==":[{"var":"isGrid"},false]},{"==":[{"var":"type"},2]}]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "fieldCodes",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 1,
          id: uuidv4()
        },
        {
          code: "gridCode",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 5,
          id: uuidv4()
        },
        {
          code: "gridIdentifierValue",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 6,
          id: uuidv4()
        },
        {
          code: "documentType",
          isHidden: false,
          isRequired: true,
          hidden: false,
          type: 2,
          priority: 4,
          id: uuidv4()
        },
        {
          code: "additionalInformation",
          name: "Additional Document",
          isHidden: true,
          isRequired: false,
          hidden: true,
          type: 2,
          priority: 2,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Checklist Document",
      code: "checklistDocument",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"documentType"},0]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "additionalInformation",
          name: "CheckList",
          isHidden: false,
          isRequired: true,
          hidden: false,
          masterDataType: "additionalInformationCodes",
          type: 2,
          priority: 2,
          id: uuidv4()
        }
      ]
    },
    {
      name: "Additional Document",
      code: "additionalDocument",
      id: uuidv4(),
      expression: {
        representations: [
          {
            representationValue: `{"==":[{"var":"documentType"},1]}`,
            representationType: 4
          }
        ]
      },
      outcomes: [
        {
          code: "additionalInformation",
          name: "Additional Document",
          isHidden: false,
          isRequired: true,
          hidden: false,
          masterDataType: "DocumentGroups",
          type: 2,
          priority: 2,
          id: uuidv4()
        }
      ]
    }
  ];
};

export const createAllocationConditions = () => {
  return [];
};
