import React, {useEffect} from "react";
import { codeRegexPattern } from "../../../_helpers/staticFields";

export const ImportModalMarkup = ({
  name,
  added,
  failed,
  edited,
  showEdit = false,
  importItems,
  importStatus,
  messageStatus,
  validationStatus,
  workflowResponse,
  pause,
  stop,
  pending,
  setPending
}) => {
  let column = "col-4";
  if (showEdit) {
    column = "col-3";
  }

  let workflowStatus = [
    "In Progress",
    "Completed",
    "Awaiting Stage Start",
    "Awaiting Authentication"
  ];

  useEffect(() => {
    if (importItems?.length > 0) {
      setPending(importItems.length - (added + failed + edited));
    }
    // eslint-disable-next-line
  }, [added, edited, failed]);

  return (
    <>
      <div className="row no-gutters mx-0 px-8 bg-gray-100">
        <div className={`${column} py-3 px-6`}>
          <div className="card-body bg-white text-center px-2 py-0 mx-10 rounded import-counter-primary">
            <h1 className="font-weight-light mt-1 mb-0"> {added} </h1>
            <h5 className="pt-1 text-primary font-weight-light">Added</h5>
          </div>
        </div>

        {showEdit ? (
          <div className={`${column} py-3 px-6`}>
            <div className="card-body bg-white text-center px-2 py-0 mx-10 rounded import-counter-primary">
              <h1 className="font-weight-light mt-1 mb-0"> {edited} </h1>
              <h5 className="pt-1 text-primary font-weight-light">Edited</h5>
            </div>
          </div>
        ) : null}

        <div className={`${column} py-3 px-6`}>
          <div className="card-body bg-white text-center px-2 py-0 mx-10 rounded import-counter-danger">
            <h1 className="font-weight-light mt-1 mb-0"> {failed} </h1>
            <h5 className="pt-1 text-danger font-weight-light">Failed</h5>
          </div>
        </div>

        <div className={`${column} py-3 px-6`}>
          <div className="card-body bg-white text-center px-2 py-0 mx-10 rounded import-counter-warning">
            <h1 className="font-weight-light mt-1 mb-0">
              {" "}
              {pending}{" "}
            </h1>
            <h5 className="pt-1 text-warning font-weight-light">Pending</h5>
          </div>
        </div>
      </div>

      {name === "workflow" ? (
        <div className="mx-auto mt-4 import-modal-table">
          <table className="table mb-0">
            <thead>
              <tr className="text-center">
                <th> Identifier</th>
                <th> Product Version</th>
                <th> Scenario Code</th>
                <th> Status</th>
              </tr>
            </thead>
            <tbody>
              {workflowResponse.length === 0 ? (
                <tr className="text-center">
                  <td>
                    <span
                      className="spinner"
                      style={{ border: "1px solid transparent" }}
                    ></span>
                  </td>
                  <td>
                    <span
                      className="spinner"
                      style={{ border: "1px solid transparent" }}
                    ></span>
                  </td>
                  <td>
                    <span
                      className="spinner"
                      style={{ border: "1px solid transparent" }}
                    ></span>
                  </td>
                  <td>
                    <span
                      className="spinner"
                      style={{ border: "1px solid transparent" }}
                    ></span>
                  </td>
                </tr>
              ) : (
                workflowResponse.map((res, index) => (
                  <>
                    {res.identifier ? (
                      <tr className="text-center" key={index}>
                        <td> {res.identifier} </td>
                        <td> {res.productVersion} </td>
                        <td> {res.scenarioCode ? res.scenarioCode : "-"} </td>
                        <td>
                          <span className="label label-lg label-inline label-light-primary">
                            {" "}
                            {workflowStatus[res.status]}{" "}
                          </span>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mx-auto mt-4 import-modal-table">
          <table className="table mb-0">
            <thead className="">
              <tr className="text-center">
                <th> Name</th>
                <th> Code</th>
                <th> Description</th>
                <th> Status</th>
                <th> Import Type</th>
                <th> Error Message</th>
              </tr>
            </thead>
            <tbody>
              {importItems.map((importItem, index) => {
                return (
                  <tr className="text-center" key={index}>
                    <td>
                      {" "}
                      {String(importItem.name || importItem.Name || "").trim()
                        .length
                        ? importItem.name || importItem.Name
                        : "-"}{" "}
                    </td>
                    <td>
                      {String(importItem.code || importItem.Code || "").trim()
                        .length
                        ? String(
                            importItem.code || importItem.Code || ""
                          ).replace(codeRegexPattern, "")
                        : "-"}
                    </td>
                    <td>
                      {" "}
                      {String(importItem.description || "").trim().length
                        ? importItem.description
                        : "-"}{" "}
                    </td>
                    <td className="text-center">
                      {importStatus[index] ? (
                        <span
                          className={`label label-lg label-inline ${
                            importStatus[index] !== "Failed"
                              ? "label-light-primary"
                              : "label-light-danger"
                          }`}
                        >
                          {importStatus[index]}
                        </span>
                      ) : pause ? (
                        <span
                          className={`label label-lg label-inline label-light-info`}
                        >
                          Paused
                        </span>
                      ) : stop ? (
                        <span
                          className={`label label-lg label-inline label-light-danger`}
                        >
                          Stopped
                        </span>
                      ) : (
                        <div>
                          <span
                            className="spinner"
                            style={{ border: "1px solid transparent" }}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      {" "}
                      {importItem?.importType?.toString()?.toLowerCase() ===
                      "edit"
                        ? "Edit"
                        : "Add"}{" "}
                    </td>
                    <td
                      className={`${
                        messageStatus[index] || validationStatus[index]
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      {validationStatus[index] ? (
                        <>
                          {" "}
                          <span> {validationStatus[index]} </span> <br />{" "}
                        </>
                      ) : null}
                      {messageStatus[index] ? (
                        Array.isArray(messageStatus[index]) &&
                        messageStatus[index]?.length ? (
                          <span>
                            {" "}
                            {(messageStatus[index] || [])?.map((v, i) => {
                              let separator =
                                messageStatus[index]?.length - 1 === i
                                  ? ""
                                  : messageStatus[index]?.length - 2 === i
                                  ? "and"
                                  : ",";
                              return (
                                <span>
                                  {v} {separator}{" "}
                                </span>
                              );
                            })}{" "}
                            {messageStatus[index].length === 1 ? "is" : "are"}{" "}
                            required.{" "}
                          </span>
                        ) : (
                          messageStatus[index]
                        )
                      ) : null}

                      {!messageStatus[index] && !validationStatus[index]
                        ? "-"
                        : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
