import axios from "axios";
import qs from "qs";
import { generateRandomString } from "../../../helper/functions";

const workflowsURL = "/edith/api/v1.0/workflows";
const bucketURL = "/multiverse/api/v1.0/documents";
const multiverseVersionURL = "/multiverse/api/v1.0/versions";
const externalApisURL = "/sofi/api/v1.0/ExternalApis";
const customersApisURL = "/pensieve";
const discrepancyURL = "/edith/api/v1.0/discrepancy";
const advicesURL = "/edith/api/v1.0/advice";
const falconURL = "/falcon/api/v1.0/credit";
const accountStatementURL = "/edith/api/v1.0";
const loginViaTokenURL = "/identity/api/v1.0/oauth/LoginViaToken";
const masterWorkflowsURL = "/edith/api/v1.0/MasterWorkflows";
const { REACT_APP_ACCOUNT_STATEMENT_PARSER } = process.env;

export function createWorkflow(params) {
  return axios.post(workflowsURL, params);
}

export function workflowInitiateAndProceedWithDocument(params) {
  return axios.post(workflowsURL + `/InitiateAndProceedWithDocument`, params);
}

export function getAllWorkflows(params) {
  return axios.get(workflowsURL, {
    params,
    paramsSerializer: paramsInner =>
      qs.stringify(paramsInner, { arrayFormat: "comma", encode: false })
  });
}

export function getWorkflowById({ productCode, identifier }) {
  return axios.get(workflowsURL + `/${productCode}/${identifier}`);
}

export function getPreviousDeviations({ productCode, identifier }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/PreviousDeviations`
  );
}

export function getWorkflowStages({ productCode, identifier }) {
  return axios.get(workflowsURL + `/${productCode}/${identifier}/Stages`);
}

export function getWorkflowStageById({ productCode, identifier, stageCode }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/Stages/${stageCode}`
  );
}

export function saveWorkflow({
  productCode,
  identifier,
  withValidation,
  data
}) {
  return axios.post(
    workflowsURL +
      `/${productCode}/${identifier}/save?withValidation=${withValidation ===
        1}`,
    { ...(withValidation === 0 ? {} : data) }
  );
}

export function validateWorkflow({
  productCode,
  identifier,
  withValidation,
  data,
  overrideHierarchy
}) {
  return axios.post(
    masterWorkflowsURL + `/${productCode}/${identifier}/ComputeFields`,
    { ...data, ...overrideHierarchy }
  );
}

export function saveValidateWorkflow({
  productCode,
  identifier,
  withValidation,
  data,
  overrideHierarchy
}) {
  return axios.post(
    workflowsURL + `/${productCode}/${identifier}/save?withValidation=true`,
    { ...data, ...overrideHierarchy }
  );
}

export function proceedWorkflow({
  productCode,
  identifier,
  data,
  overrideHierarchy
}) {
  return axios.post(workflowsURL + `/${productCode}/${identifier}/proceed`, {
    ...data,
    ...overrideHierarchy
  });
}

export function precedeWorkflow({
  productCode,
  identifier,
  stageCode,
  comments
}) {
  return axios.post(workflowsURL + `/${productCode}/${identifier}/precede`, {
    stageCode,
    comments
  });
}

export function statusUpdateWorkflow(params) {
  return axios.post(
    workflowsURL + `/${params.productCode}/${params.identifier}/StatusUpdate`,
    params
  );
}

export function createException({ productCode, identifier, exception }) {
  return axios.post(workflowsURL + `/${productCode}/${identifier}/Exception`, {
    ...exception
  });
}

export function approveRejectException({ productCode, identifier, exception }) {
  return axios.post(
    workflowsURL + `/${productCode}/${identifier}/Exception/${exception.code}`,
    { ...exception }
  );
}

export function processAuthentication({
  productCode,
  identifier,
  fields,
  gridFields,
  comments,
  action,
  token
}) {
  return axios.post(
    workflowsURL + `/${productCode}/${identifier}/Authenticate`,
    { fields, gridFields, comments, action, token }
  );
}

export function reassignmentGroup(params) {
  return axios.post(workflowsURL + `/ReassignWorkflowsAuthenticationGroup`, {
    ...params
  });
}

export function reassignmentUser(params) {
  return axios.post(workflowsURL + `/ReassignWorkflows`, { ...params });
}
export function assignmentUser(params) {
  return axios.post(workflowsURL + `/AssignWorkflows`, { ...params });
}

export function deleteException({ productCode, identifier, exceptionCode }) {
  return axios.delete(
    workflowsURL + `/${productCode}/${identifier}/Exception/${exceptionCode}`
  );
}

export function uploadDocument(params) {
  return axios.post(bucketURL, { ...params });
}

export function downloadDocument(params) {
  return axios.get(bucketURL + `/${params.bucketCode}/${params.identifier}`);
}

export function getDocumentByIdentifier(params) {
  return axios.get(bucketURL, { params });
}

export function downloadVersionDocument(params) {
  return axios.get(
    multiverseVersionURL + `/${params?.identifier}/${params?.versionCount}`
  );
}

export function deleteDocument(id) {
  return axios.delete(bucketURL + `/deleteDocumentByCode/${id}`);
}

export function integrateWorkflow({ productCode, identifier, data }) {
  return axios.post(workflowsURL + `/${productCode}/${identifier}/Integrate`, {
    ...data
  });
}

export function getStageCheckList({ productCode, identifier, stageCode }) {
  return axios.get(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/GetStageCheckListItems`
  );
}

export function processCheckList({
  productCode,
  identifier,
  checkListCode,
  activity,
  comments,
  deferredDate
}) {
  return axios.post(
    workflowsURL +
      `/${productCode}/${identifier}/${checkListCode}/CompleteCheckListActivityItem`,
    deferredDate && comments
      ? { activity, deferredDate, comments }
      : deferredDate
      ? { activity, deferredDate }
      : comments
      ? { activity, comments }
      : { activity }
  );
}

export function getIntegration({
  productCode,
  identifier,
  stageCode,
  checkListCode
}) {
  return axios.get(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/Integrations/${checkListCode}`
  );
}

export function getIntegrationForPreCheck({
  productCode,
  identifier,
  stageCode,
  checkListCode
}) {
  return axios.get(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/Integrations/${checkListCode}/Prechecks`
  );
}

export function getApprovingAuthority({ productCode, identifier }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/ApprovingAuthority`
  );
}

export function getWorkflowTrail({ productCode, identifier }) {
  return axios.get(workflowsURL + `/${productCode}/${identifier}/Trail`);
}

export function fetchAuthenticationsByStage({
  productCode,
  identifier,
  stageCode
}) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/${stageCode}/Authentications`
  );
}

export function fetchExceptionsByAuthentication({
  productCode,
  identifier,
  stageCode,
  ...params
}) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/${stageCode}/Exceptions`,
    { params }
  );
}

export function getRejectedApplication({ productCode, identifier }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/RejectedFlows`
  );
}

export function fetchDiscrepancies({ productCode, identifier, ...params }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/Discrepancies`,
    { params }
  );
}

export function createDiscrepancy({ productCode, identifier, discrepancy }) {
  return axios.post(
    workflowsURL + `/${productCode}/${identifier}/Discrepancies`,
    { ...discrepancy }
  );
}

export function createLegacyDiscrepancy({
  productCode,
  identifier,
  discrepancy,
  stageCode
}) {
  return axios.post(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/Legacy/Discrepancies`,
    { ...discrepancy }
  );
}

export function updateLegacyDiscrepancy({
  productCode,
  identifier,
  discrepancy
}) {
  return axios.put(
    workflowsURL + `/${productCode}/${identifier}/Legacy/UpdateDiscrepancies`,
    { ...discrepancy }
  );
}

export function approveRejectDiscrepancy({
  productCode,
  identifier,
  discrepancy
}) {
  return axios.put(
    workflowsURL +
      `/${productCode}/${identifier}/Discrepancies/${discrepancy?.code}`,
    { ...discrepancy }
  );
}

export function deleteDiscrepancy({
  productCode,
  identifier,
  discrepancyCode
}) {
  return axios.delete(
    workflowsURL +
      `/${productCode}/${identifier}/Discrepancies/${discrepancyCode}`
  );
}

export function getExposureSheet({ productCode, identifier, selectedBureau }) {
  if (selectedBureau) {
    return axios.get(
      workflowsURL +
        `/${productCode}/${identifier}/ExposureSheet/${selectedBureau}`
    );
  } else {
    return axios.get(
      workflowsURL + `/${productCode}/${identifier}/ExposureSheet`
    );
  }
}

export function createExposureSheet({
  productCode,
  identifier,
  selectedBureau,
  ...exposureSheet
}) {
  if (selectedBureau) {
    return axios.post(
      workflowsURL +
        `/${productCode}/${identifier}/ExposureSheet/${selectedBureau}`,
      exposureSheet
    );
  } else {
    return axios.post(
      workflowsURL + `/${productCode}/${identifier}/ExposureSheet`,
      exposureSheet
    );
  }
}

export function updateExposureSheet({
  productCode,
  identifier,
  selectedBureau,
  ...exposureSheet
}) {
  if (selectedBureau) {
    return axios.post(
      workflowsURL +
        `/${productCode}/${identifier}/ExposureSheet/${selectedBureau}`,
      exposureSheet
    );
  } else {
    return axios.post(
      workflowsURL + `/${productCode}/${identifier}/ExposureSheet`,
      exposureSheet
    );
  }
}

export function createDisbursementFile({ productCode, ...params }) {
  return axios.post(workflowsURL + `/${productCode}/DisbursementFile`, {
    ...params,
    productCode
  });
}

export function createRectification({ productCode, identifier, data }) {
  return axios.post(
    workflowsURL + `/${productCode}/${identifier}/Rectification`,
    { ...data }
  );
}

export function fetchPaymentPlan({ productCode, identifier }) {
  return axios.get(
    externalApisURL + `/${productCode}/${identifier}/PaymentPlan`
  );
}

export function fetchInstallmentPlan({ productCode, identifier }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/InstallmentPlan`
  );
}

export function getCustomerFromCNIC(params) {
  return axios.get(customersApisURL + `/customers/${params}`);
}

export function getAllCustomers({ productCode, identifier, ...params }) {
  return axios.get(
    customersApisURL + `/${productCode}/${identifier}/customers`,
    { params }
  );
}

export function createCustomer(params) {
  return axios.post(
    customersApisURL + `/${params.productCode}/${params.identifier}/customers`,
    { ...params?.values }
  );
}

export function updateCustomer(params) {
  return axios.put(
    customersApisURL +
      `/${params.productCode}/${params.identifier}/customers/${params.cnic}`
  );
}

export function deleteCustomer(params) {
  return axios.delete(
    customersApisURL +
      `/${params.productCode}/${params.identifier}/customers/${params.cnic}`
  );
}

export function generateDataCheckFile(params) {
  return axios.post(workflowsURL + `/DataCheckFile`, params);
}

export function createStageDiscrepancy({ productCode, identifier, ...params }) {
  return axios.post(discrepancyURL + `/${productCode}/${identifier}`, {
    ...params
  });
}

export function updateStageDiscrepancy({
  productCode,
  identifier,
  discrepancyCode,
  ...params
}) {
  return axios.put(
    discrepancyURL + `/${productCode}/${identifier}/${discrepancyCode}`,
    { ...params }
  );
}

export function getAllDiscrepancies({ productCode, identifier, ...params }) {
  return axios.get(discrepancyURL + `/${productCode}/${identifier}`, {
    params
  });
}

export function getAllStageDiscrepancies({
  productCode,
  identifier,
  stageCode,
  ...params
}) {
  return axios.get(
    discrepancyURL +
      `/${productCode}/${identifier}/${stageCode}/StageDiscrepancies`,
    { params }
  );
}

export function getAllAdvices({ productCode, identifier, ...params }) {
  return axios.get(advicesURL + `/${productCode}/${identifier}`, { params });
}
export function getAllStageAdvices({
  productCode,
  identifier,
  stageCode,
  ...params
}) {
  return axios.get(
    advicesURL + `/${productCode}/${identifier}/${stageCode}/StageAdvices`,
    {
      params
    }
  );
}
export function createAdvice({ productCode, identifier, ...params }) {
  return axios.post(advicesURL + `/${productCode}/${identifier}`, {
    ...params
  });
}

export function updateAdvice({ productCode, identifier, code, ...params }) {
  return axios.put(advicesURL + `/${productCode}/${identifier}/${code}`, {
    ...params
  });
}

export function parseAccountStatement(params) {
  return axios.post(REACT_APP_ACCOUNT_STATEMENT_PARSER, params);
}

export function deleteDynamicFormGridRow({
  productCode,
  identifier,
  stageCode,
  gridCode,
  gridIdentifierCode,
  gridIdentifierValue
}) {
  return axios.delete(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/${gridCode}/${gridIdentifierCode}/${gridIdentifierValue}`
  );
}

export function getDeletedGridRows({
  productCode,
  identifier,
  stageCode,
  gridCode
}) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/${stageCode}/${gridCode}`
  );
}

export function fetchTransactionsLogs(params) {
  return axios.get(falconURL + `/transaction-logs`, {
    params,
    paramsSerializer: paramsInner =>
      qs.stringify(paramsInner, { arrayFormat: "comma", encode: false }),
    headers: {
      productCode: params.productCode,
      channelId: "CASCADE",
      traceId: generateRandomString(10)
    }
  });
}

export function fetchCreditInquiry(params) {
  return axios.get(falconURL + `/inquiry`, {
    params,
    paramsSerializer: paramsInner =>
      qs.stringify(paramsInner, { arrayFormat: "comma", encode: false }),
    headers: {
      productCode: params.productCode,
      channelId: "CASCADE",
      traceId: generateRandomString(10)
    }
  });
}

export function runScreeningChecks(params) {
  return axios.post(workflowsURL + `/Screening`, {
    ...params
  });
}

export function fetchScreeningChecksResult({
  productCode,
  identifier,
  screeningCode
}) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/Screening/${screeningCode}`
  );
}

export function fetchAccountStatement({
  productCode,
  workflowIdentifier,
  identifier,
  ...params
}) {
  return axios.get(
    accountStatementURL +
      `/AccountStatements/${productCode}/${workflowIdentifier}/${identifier}`,
    {
      ...params
    }
  );
}

export function fetchMetaData({ productCode, workflowIdentifier, ...params }) {
  return axios.get(
    accountStatementURL +
      `/AccountStatements/${productCode}/${workflowIdentifier}`,
    {
      ...params
    }
  );
}

export function updateTransactions({ productCode, identifier, ...params }) {
  return axios.put(
    accountStatementURL + `/AccountStatements/${productCode}/${identifier}`,
    {
      ...params
    }
  );
}

export function processChecklistWithAction(body, params) {
  return axios.post(
    workflowsURL +
      `/${params?.productCode}/${params?.identifier}/${params?.checkListCode}/PerformCheckListActivityItem`,
    {
      ...body
    }
  );
}

export function mailApprovalPassword(params) {
  return axios.post(loginViaTokenURL, {
    ...params
  });
}

export function getApprovingAuthorityLite({ productCode, identifier }) {
  return axios.get(
    workflowsURL + `/${productCode}/${identifier}/ApprovingAuthorityLite`
  );
}

export function getLatestStageAuthentication({
  productCode,
  identifier,
  stageCode
}) {
  return axios.get(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/LatestStageAuthentication`
  );
}

export function getLatestStageExceptions({
  productCode,
  identifier,
  stageCode,
  ...params
}) {
  return axios.get(
    workflowsURL +
      `/${productCode}/${identifier}/${stageCode}/LatestStageExceptions`,
    { params }
  );
}

export function getRepricedPortfolios({ productCode, ...params }) {
  return axios.get(workflowsURL + `/${productCode}/GetRepricedPortfolios`, {
    params
  });
}
export function getRepricing({ productCode, identifier }) {
  return axios.get(workflowsURL + `/${productCode}/${identifier}/Repricing`);
}

export function getBalloonPortfolios({ productCode, ...params }) {
  return axios.get(workflowsURL + `/${productCode}/GetBalloonPortfolios`, {
    params
  });
}
export function getBalloon({ productCode, identifier, ...params }) {
  return axios.get(workflowsURL + `/${productCode}/${identifier}/Balloon`, {
    params
  });
}

export function documentComments({ data, ...params }) {
  return axios.put(
    multiverseVersionURL +
      `/${params?.identifier}/${params?.versionCount}/Notes`,
    {
      ...data
    }
  );
}

export function getDocumentComments({ ...params }) {
  return axios.get(
    `${multiverseVersionURL}?DocumentId=${params?.identifier}&paginationOverride=${params?.paginationOverride}`
  );
}

export function addNotesAgainstWorkflow({ data, ...params }) {
  return axios.post(
    workflowsURL + `/${params?.productCode}/${params?.identifier}/Notes`,
    {
      ...data
    }
  );
}

export function fetchRetryCompletedFailed({ ...params }) {
  return axios.post(
    workflowsURL + `/${params?.productCode}/${params?.identifier}/Retry`
  );
}

export function deleteDocumentByVersion(params) {
  return axios.delete(
    multiverseVersionURL + `/${params?.id}/${params?.version}`
  );
}

export function fetchManuallyDisbursed({ data, ...params }) {
  return axios.post(workflowsURL + `/${params?.identifier}/ManuallyDisburse`, {
    ...data
  });
}

export function getMasterEnquiryWorkflows(params) {
  return axios.get(`${masterWorkflowsURL}/MasterEnquiry`, {
    params,
    paramsSerializer: paramsInner =>
      qs.stringify(paramsInner, { arrayFormat: "comma", encode: false })
  });
}

export function getDedupingCriteria({productCode, identifier}) {
  return axios.get(
      workflowsURL + `/${productCode}/${identifier}/DedupeCriteriaMatches`
  );
}