import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as actions from "../../../../app/modules/MasterData/_redux/records/recordsActions";
import {
  fetchProduct,
  fetchProducts
} from "../../../../app/modules/ProdFactory/_redux/products/productsActions";
import { SkeletonComponent } from "./Skeleton";
import {sortDropdownsOptions} from "../../../_helpers";
import { fetchProductStages } from "../../../../app/modules/NotificationTemplates/_redux/actions";
import { fetchFields } from "../../../../app/modules/MasterDataConfiguration/_redux/fields/fieldsActions";
import { fetchRecords } from "../../../../app/modules/MasterData/_redux/records/recordsActions";

const animatedComponents = makeAnimated();

export function ReactSelectControlled(props) {
  const {
    name,
    disabled,
    error,
    emptyOptionsMessage,
    parentWaitingMessage,
    value,
    masterDataType,
    isMulti,
    dependencyTree,
    allValues,
    shouldUseLocalDropdownOptions,
    isReadOnly,
    hideCodeInDropdownLabel,
    setFieldValue,
    overwriteSorting = false,
    stage,
    activeStageCode,
    workflow,
    isStageForm
    // allParentValuesMandatory,
  } = props;

  const {
    dropdownOptions,
    products,
    hierarchies,
    allFields,
    allUsers,
    productDictionary
  } = useSelector(
    state => ({
      dropdownOptions:
        state.records?.[masterDataType]?.entities ||
        typeof dependencyTree[name] === "string"
          ? state.records?.[masterDataType]?.[name]
          : state.records?.[masterDataType]?.records,
      products: state.products.entities || [],
      productDictionary: state.products.productDictionary,
      allFields: state.fields.entities || [],
      hierarchies: state.auth?.user?.hierarchies || [],
      allUsers: state.users.allUsers,
    }),
    shallowEqual
  );

  const [options, setOptions] = useState([]);
  const [isFetched, setFetched] = useState(true);
  const [parentField, setParentField] = useState(null);
  const [parentValue, setParentValue] = useState(null);
  const [stages, setStages] = useState([]);
  const [hierarchy, setHierarchy] = useState([]);
  const [optionsValuesExists, setOptionsValuesExists] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isParentSelectedBeforeChild, setIsParentSelectedBeforeChild] = useState(false);


  useEffect(() => {
    if (isReadOnly ||
        disabled ||
        (props?.isReverseDependentDropDown
            ? !props?.options
            : !options?.length)) {
      setIsMenuOpen(false);
    }
    // eslint-disable-next-line
  }, [disabled, isMenuOpen]);

  // fetch options from api on mount
  useEffect(() => {
    if (!options.length && !shouldUseLocalDropdownOptions)
      getDropdownOptionsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stage?.code === activeStageCode && parentValue && isStageForm) {
      setFetched(false);
      getDropdownOptionsFromApi();
    }
    // eslint-disable-next-line
  }, [activeStageCode]);

  useEffect(() => {
    let tempParentField = dependencyTree[name];
    let tempParentValue = tempParentField ? allValues[tempParentField] : "";

    if (props?.isReverseDependentDropDown) {
      if (value) {
        let parentValueFromRecords =
            props?.options?.find(data => data?.value === value)?.values?.[
                tempParentField
                ] ||
            allValues[tempParentField] ||
            "";

        setIsParentSelectedBeforeChild(false);

        setParentField(tempParentField === [] ? null : tempParentField);
        setParentValue(value ? parentValueFromRecords : "");
        setFieldValue(tempParentField, value ? parentValueFromRecords : "");
      } else {
        setIsParentSelectedBeforeChild(true);
        if (tempParentValue !== parentValue) {
          setParentField(tempParentField === [] ? null : tempParentField);
          setParentValue(tempParentValue);
        } else {
            let parentValueFromRecords =
                props?.options?.find(data => data?.value === value)?.values?.[
                    tempParentField
                    ] ||
                allValues[tempParentField] ||
                "";
            setParentField(tempParentField === [] ? null : tempParentField);
            setParentValue(value ? parentValueFromRecords : "");
            setFieldValue(tempParentField, value ? parentValueFromRecords : "");
        }
      }
    } else {
      setIsParentSelectedBeforeChild(true)
      if (tempParentValue !== parentValue) {
        setParentField(tempParentField === [] ? null : tempParentField);
        setParentValue(tempParentValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  useEffect(() => {
    if (
      props?.isReverseDependentDropDown &&
      props?.options?.length &&
      Object.keys(allValues)?.length &&
      !optionsValuesExists
    ) {
      let isValuesFetched = props?.options?.some(
        data => data?.values !== undefined
      );
      if (props?.isReverseDependentDropDown && isValuesFetched) {
        setOptionsValuesExists(true);
        let tempParentField = dependencyTree[name];
        let parentValueFromRecords =
          props?.options?.find(data => data?.value === value)?.values?.[
            tempParentField
          ] ||
          allValues[tempParentField] ||
          "";
        setParentField(tempParentField === [] ? null : tempParentField);
        setParentValue(value ? parentValueFromRecords : "");

        setFieldValue(tempParentField, value ? parentValueFromRecords : "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.options]);

  // whenever parent value changes, fetch options
  useEffect(() => {
    if (parentValue) {
      setFetched(false);
      getDropdownOptionsFromApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValue]);

  useEffect(() => {
    if (
      props?.name === "ProductHierarchy" ||
      props?.name === "ProductCodesMandatory" ||
      props?.name === "ProductCodes" ||
      props?.name === "StageCodes"
    ) {
      onProductChange();
    }
    // eslint-disable-next-line
  }, [allValues["ProductCodes"], allValues["ProductCodesMandatory"], products]);

  const onProductChange = () => {
    if (products?.length) {
      const productCodesString = allValues["ProductCodes"];
      const stageCodeString = allValues["StageCodes"];
      const mandatoryProductCodesString = allValues["ProductCodesMandatory"];
      const hierarchyCodeString = allValues["ProductHierarchy"];

      if (productCodesString) {
        const selectedProductCodes = productCodesString
          .split("!")
          .map(code => code.trim())
          .filter(code => code !== "");
        let promises = [];
        let stagesArray = [];
        let stageCodesString;
        if (selectedProductCodes.length > 0) {
          (selectedProductCodes || []).forEach(selectedProductCode => {
            const promise = dispatch(
              fetchProductStages({ id: selectedProductCode })
            ).then(res => {
              const productStages = res?.stages;
              if (productStages) {
                const modifiedStages = (productStages || []).map(stage => ({
                  name: `${stage.name} (${productDictionary[selectedProductCode]?.name})`,
                  code: stage.stageCode
                }));
                stagesArray = [...stagesArray, ...modifiedStages];
              }
            });

            promises.push(promise);
          });

          // Wait for all promises to resolve before updating 'stagesArray'
          Promise.all(promises).then(() => {
            setStages([...stagesArray]);

            if (stagesArray?.length > 0) {
              if (stageCodeString?.length) {
                const selectedStageCodes = stageCodeString
                  ?.split("!")
                  .map(code => code.trim())
                  .filter(code => code !== "");

                const newStageCodes = (
                  selectedStageCodes || []
                ).filter(stageCode =>
                  stagesArray?.some(stage => stage.code === stageCode)
                );

                const newStageCodesString =
                  newStageCodes?.length === 1
                    ? newStageCodes[0] + "!"
                    : newStageCodes.join("!");

                stageCodesString = newStageCodesString; // Update stageCodeString
              }
            }

            setFieldValue("StageCodes", stageCodesString);
          });
        } else {
          setStages([]);
        }
      } else {
        setStages([]);
      }

      if (mandatoryProductCodesString) {
        const selectedProductCodes = mandatoryProductCodesString
          .split("!")
          .map(code => code.trim())
          .filter(code => code !== "");

        let hierarchyArray = [];
        let hierarchyCodesString = hierarchyCodeString;

        if (selectedProductCodes.length > 0) {
          // Use map to create an array of promises
          const promises = selectedProductCodes.map(selectedProductCode => {
            return dispatch(fetchProduct({ code: selectedProductCode })).then(res => {
              let fetchProduct = res?.data;
              return dispatch(
                fetchRecords({
                  entityCode: fetchProduct?.hierarchyType,
                  paginationOverride: true
                })
              ).then(res => {
                const productHierarchies = res?.data?.items;

                if (productHierarchies) {
                  const modifiedHierarchy = (productHierarchies || []).map(stage => ({
                    name: `${stage.name}`,
                    code: stage.code
                  }));
                  hierarchyArray = [...hierarchyArray, ...modifiedHierarchy];
                }
              });
            });
          });

          // Wait for all promises to resolve before updating 'hierarchyArray'
          return Promise.all(promises).then(() => {
            setHierarchy([...hierarchyArray]);

            if (hierarchyArray?.length > 0) {
              if (hierarchyCodeString?.length) {
                 const newHierarchyCodes = hierarchyArray?.some(stage => stage.code === hierarchyCodeString)
                hierarchyCodesString = newHierarchyCodes ? hierarchyCodeString : null; // Update stageCodeString
              }
            }

            setFieldValue("ProductHierarchy", hierarchyCodesString);
          });
        } else {
          setHierarchy([]);
        }
      } else {
        setHierarchy([]);
      }

    }
  };

  // if options are available and not same as stored in this component, set options set fetched to true;
  useEffect(() => {
    let filteredOptions = [];

    if (
      props.name === "ProductCode" ||
      props.name === "ProductCodes" ||
      props.name === "ProductCodesMandatory"
    ) {
      filteredOptions = products;
    }
    if (props.name === "StageCodes") {
      filteredOptions = stages;
    }
    if (
      (props?.tags || []).find(
        tag =>
          tag === "FieldMasterDataType" || tag?.code === "FieldMasterDataType"
      ) || (props.name === "allFields" || props.name === "allField" || props?.name === "richTextBoxField" || props?.name === "richTextBoxTranslationField")
    ) {
      filteredOptions = allFields;
    }
    if (props.name === "allUsers" || props.name === "allUser") {
      filteredOptions = allUsers;
    }
    if (props.name === "ProductHierarchy") {
      filteredOptions = hierarchy;
    } else {
      if (Object.entries(parentField || {}).length) {
        if (allValues[parentField]) {
          filteredOptions = dropdownOptions;
        }
      } else {
        filteredOptions = dropdownOptions;
      }
    }

    if (
      options?.length &&
      !filteredOptions?.length &&
      (value?.length || value || value === 0)
    ) {
      onChangeDropdown(isMulti ? [] : "");
    }
    if (hierarchies?.length) {
      hierarchies.forEach(hierarchy => {
        if (
          hierarchy?.code === masterDataType &&
          hierarchy?.assignedCodes?.length &&
          filteredOptions?.length
        ) {
          filteredOptions = filteredOptions.filter(item =>
            hierarchy?.assignedCodes.includes(item.code)
          );
        }
      });
    }

    if (
      shouldUseLocalDropdownOptions &&
      !parentValue &&
      parentField &&
      dropdownOptions?.length
    ) {
      dispatch(
        actions.clearParentFieldRecords({
          entityCode: masterDataType,
          parentField
        })
      );
      filteredOptions = [];
    }
    getDropdownOptions(filteredOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOptions, parentValue, parentField, stages, hierarchy]);

  useEffect(() => {
    let filteredOptions = [];
    let stagesOptions = [];
    let userOptions = [];
    let hierarchyOptions = [];
    if (products?.length) {
      if (
        props.name === "ProductCode" ||
        props?.name === "ProductCodes" ||
        props.name === "ProductCodesMandatory"
      ) {
        filteredOptions = products;
        getDropdownOptions([...filteredOptions]);
      }
    }
    if (allUsers?.length) {
      if (props?.name === "allUsers" || props?.name === "allUser") {
        userOptions = allUsers;
        getDropdownOptions([...userOptions]);
      }
    }

    if (stages?.length) {
      if (props?.name === "StageCodes") {
        stagesOptions = stages;
        getDropdownOptions([...stagesOptions]);
      }
    }

    if (hierarchy?.length) {
      if (props?.name === "ProductHierarchy") {
        hierarchyOptions = hierarchy;
        getDropdownOptions([...hierarchyOptions]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, stages, allUsers, hierarchy]);

  useEffect(() => {
    let fieldOptions = [];
    if (allFields?.length) {
      if (
        (props?.tags || []).find(
          tag =>
            tag === "FieldMasterDataType" || tag?.code === "FieldMasterDataType"
        ) || (props.name === "allFields" || props.name === "allField" || props?.name === "richTextBoxField" || props?.name === "richTextBoxTranslationField")
      ) {
        fieldOptions = allFields;
        getDropdownOptions([...fieldOptions]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields]);

  const dispatch = useDispatch();
  const getDropdownOptionsFromApi = () => {
    // by default params should be override true (assume independent)
    let useLocalData = false;
    let params = {
      paginationOverride: true
    };

    let optionsToCheck = props?.isReverseDependentDropDown && !isParentSelectedBeforeChild
      ? props?.options
      : dropdownOptions;

    if (parentField && parentValue) {
      params[parentField] = parentValue;
      params["fieldCode"] = name;
      if (optionsToCheck?.length) {
        for (let i = 0; i < optionsToCheck?.length; i++) {
          if (optionsToCheck?.[i]?.values) {
            if (optionsToCheck?.[i].values[parentField] === parentValue) {
              useLocalData = true;
              setFetched(true);
            }
          }
        }
      }
    }

    if (!useLocalData) {
      if (
        props.name === "ProductCode" ||
        props?.name === "ProductCodes" ||
        props.name === "ProductCodesMandatory"
      ) {
        setFetched(false);
        dispatch(
          fetchProducts({
            ...params
          })
        ).then(() => {
          setFetched(true);
        });
      }
      if (
        (props?.tags || []).find(
          tag =>
            tag === "FieldMasterDataType" || tag?.code === "FieldMasterDataType"
        ) || (props.name === "allFields" || props.name === "allField" || props?.name === "richTextBoxField" || props?.name === "richTextBoxTranslationField")
      ) {
        setFetched(false);
        dispatch(fetchFields({ ...params })).then(() => {
          setFetched(true);
        });
      }
      if (props.name === "StageCodes") {
        setFieldValue("StageCodes", allValues["StageCodes"]);
      }
      if (props.name === "ProductHierarchy") {
        setFieldValue("ProductHierarchy", allValues["ProductHierarchy"]);
      } else {
        dispatch(
          actions.fetchRecords({
            entityCode: masterDataType,
            ...params
          })
        ).then(response => {
          // if (value && options?.length) {
          //   setFieldValue(name, null);
          // }
          if (response?.data?.items?.length === 1 && !isMulti) {
            onChangeDropdown({
              label: response?.data?.items[0].name,
              value: response?.data?.items[0].code
            });
          }
          setFetched(true);
        });
      }
    }
  };

  const getDropdownOptions = rawOptions => {
    const selectAllOption = { label: "Select All", value: "all" };
    const updatedOptions = (rawOptions || [])
      .filter(option => option?.isHidden !== true)
      .map(({ label, value, name, code, entityName, entityCode }) => ({
        label:
          (!hideCodeInDropdownLabel
            ? (value || entityCode || code) + ": "
            : "") + (label || entityName || name),
        value: value || entityCode || code
      }));
    if (rawOptions?.length && isMulti) {
      updatedOptions.unshift(selectAllOption);
    }
    setOptions(updatedOptions);
  };

  const getDropdownSelectedValue = (options, selectedOption, dropdownType) => {
    if (isStageForm) {
      const isWorkflowCurrentStage = workflow?.stageDetails?.find(stg => stg?.stageCode === activeStageCode);
      if (isWorkflowCurrentStage?.isCurrent === false ? workflow?.fields?.[name] === null || workflow?.fields?.[name] === undefined :  selectedOption === null || selectedOption === undefined) {
        return "";
      }
      const value = isWorkflowCurrentStage?.isCurrent === false ? workflow?.fields?.[name] : selectedOption;
      if (dropdownType === "single") {
        const selOption = (options || []).find(
            item => item?.value === value
        );
        return selOption || "";
      }


      if (dropdownType === "multi") {
        let tempSelectedOptions = Array.isArray(value)
            ? value
            : value?.split("!");
        let selOption = (tempSelectedOptions || []).map(item =>
            options.find(opt => opt?.value === item)
        );
        const remainingOptions = selOption?.filter(option => option !== "all");
        return remainingOptions;
      }

      return "";
    } else {
      if (selectedOption === null || selectedOption === undefined) {
        return "";
      }

      if (dropdownType === "single") {
        const selOption = (options || []).find(
            item => item?.value === selectedOption
        );
        return selOption || "";
      }

      if (dropdownType === "multi") {
        let tempSelectedOptions = Array.isArray(selectedOption)
            ? selectedOption
            : selectedOption?.split("!");
        let selOption = (tempSelectedOptions || []).map(item =>
            options.find(opt => opt?.value === item)
        );
        const remainingOptions = selOption?.filter(option => option !== "all");
        return remainingOptions;
      }

      return "";
    }

  };

  const onChangeDropdown = selectedOptions => {
    if (!selectedOptions) {
      props.onChange("");
      return;
    }
    if (Array.isArray(selectedOptions)) {
      let isAllOption = selectedOptions?.some(
        option => option?.value === "all"
      );
      let allOptions = [];
      if (isAllOption) {
        allOptions = (options || [])?.filter(data => data?.value !== "all");
      }

      if (props?.valueAsString) {
        let selOptions = "";
        (isAllOption ? allOptions : selectedOptions).forEach(
          opt => (selOptions += opt.value + "!")
        );
        props.onChange(selOptions);
      } else {
        let selOptions = (isAllOption ? allOptions : selectedOptions)?.map(
          item => item.value
        );
        props.onChange(selOptions);
      }
    } else {
      props.onChange(selectedOptions.value);
    }
  };


  return !isFetched ? (
    <SkeletonComponent rows={1} columns={1} skeletonClass={"col-lg-12 mb-8"} />
  ) : (
    <>
      <Select
        {...props}
        value={getDropdownSelectedValue(
          props?.isReverseDependentDropDown && !isParentSelectedBeforeChild ? props?.options : options,
          value,
          isMulti ? "multi" : "single"
        )}
        options={
          value?.length === options?.length - 1
            ? options?.filter(data => data?.value !== "all")
            : overwriteSorting
            ? props?.isReverseDependentDropDown && (!isParentSelectedBeforeChild || (!value && !parentValue))
              ? props?.options
              : options
            : sortDropdownsOptions(
                props?.isReverseDependentDropDown && !isParentSelectedBeforeChild ? props?.options : options
              )
        }
        onChange={selectedOptions => {
          if (props?.isReverseDependentDropDown && parentValue && !value) {
            setIsParentSelectedBeforeChild(true);
          } else if (props?.isReverseDependentDropDown && value && parentValue) {
            setIsParentSelectedBeforeChild(false);
          }
          onChangeDropdown(selectedOptions);
        }}
        isDisabled={
          isReadOnly ||
          disabled ||
          (props?.isReverseDependentDropDown
            ? !props?.options
            : !options?.length)
        }
        components={animatedComponents}
        menuIsOpen={!(isReadOnly ||
            disabled ||
            (props?.isReverseDependentDropDown
                ? !props?.options
                : !options?.length)) && isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
          }}}
      />
      {error && !props.isHideValidations ? (
        <p className={"text-danger"}>{error}</p>
      ) : null}
      {/*{(!dropdownOptions?.[masterDataType]?.records) && isDependent ? (*/}
      {!dropdownOptions?.length &&
      parentValue &&
      !props?.isReverseDependentDropDown ? (
        <p className={"text-muted"}>{parentWaitingMessage}</p>
      ) : (props?.isReverseDependentDropDown ? (
          !props?.options
        ) : (
          !options?.length
        )) ? (
        <p className={"text-muted"}>{emptyOptionsMessage}</p>
      ) : null}
    </>
  );
}
