import React, { useEffect, useState } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { Overlay } from "../../controls/forms/Overlay";
import NumberFormat from "react-number-format";
import { FormulaViewer } from "../../Dialog/FormulaViewer";
import {handleEnterOnInputField, handleDefaultZeroOnInputField} from "../../../_helpers";

const NumberInput = props => {
  return (
    <NumberFormat isNumericString={true} thousandSeparator={true} {...props} />
  );
};

const getFieldCSSClasses = (touched, errors, isHideValidations) => {
  const classes = ["form-control"];
  if (!isHideValidations) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }

  return classes.join(" ");
};

export function NumberFormatter({
  field,
  isReadOnly,
  values,
  withFeedbackLabel = true,
  setFieldValue,
  name,
  onChange,
  disabled,
  defaultValue,
  classes,
  type,
  meta,
  showDescription,
  description,
  label,
  prefix,
  ...props
}) {
  const { touched, error } = meta;
  const [showComputationDetails, setShowComputationDetails] = useState(false);
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
    let parsedValue = field.value;
    let originalValue = (field.value || "").toString();
    let formattedValue = parsedValue;

    if (parsedValue) {
      if (parsedValue >= 1000) {
        const suffixes = ["", "K", "M", "B", "T"];
        let suffixIndex = 0;

        while (parsedValue >= 1000 && suffixIndex < suffixes.length - 1) {
          parsedValue /= 1000;
          suffixIndex++;
        }

        const roundedValue = Math.round(parsedValue * 100) / 100;
        if (roundedValue !== parsedValue) {
          formattedValue = originalValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          formattedValue =
            roundedValue.toFixed(2).replace(/\.00$/, "") +
            suffixes[suffixIndex];
        }
      }

      setDisplayValue(formattedValue);
    }
  }, [field.value]);

  const tagsLimitations = tags => {
    let limitation = {
      min: undefined,
      wholeNumber: undefined,
      decimalScale: undefined
      // max: undefined
    };
    if (
      (tags || []).find(tag => tag === "Decimal" || tag.code === "Decimal") !==
      undefined
    ) {
      limitation["min"] = true;
    }
    if (
      (tags || []).find(
        tag => tag === "WholeNumber" || tag.code === "WholeNumber"
      ) !== undefined
    ) {
      limitation["wholeNumber"] = true;
    }

    if (
      (tags || []).find(
        tag =>
          tag === "Number2DecimalPlaces" || tag.code === "Number2DecimalPlaces"
      ) !== undefined
    ) {
      limitation["decimalScale"] = 2;
    }
    return limitation;
  };

  const { min, wholeNumber, decimalScale } = tagsLimitations(props?.tags);

  const parseValue = value => {
    if (value === "") return 0;

    const lastChar = value.charAt(value.length - 1).toLowerCase();
    const numericValue = parseFloat(value);

    if (lastChar === "k") {
      return numericValue * 1000;
    } else if (lastChar === "m") {
      return numericValue * 1000000;
    } else if (lastChar === "b") {
      return numericValue * 1000000000;
    } else if (lastChar === "t") {
      return numericValue * 1000000000000;
    } else {
      return numericValue;
    }
  };

  const handleInputChange = e => {
    let inputValue = e.target.value;
    const suffixes = ["k", "m", "b", "t"];
    const prefixToRemove = "PKR ";

    inputValue = inputValue.substring(prefixToRemove?.length);

    let processedValue = inputValue;

    if (inputValue?.length > 0) {
      // Remove any non-numeric, non-decimal, or non-suffix characters
      processedValue = inputValue.replace(/[^0-9.kmbtKMBT]/g, "");
      // Ensure only one suffix is present
      const suffixIndex = processedValue
        .toLowerCase()
        .split("")
        .findIndex(char => suffixes.includes(char));

      if (suffixIndex !== -1) {
        // Remove any characters after the suffix
        processedValue = processedValue.slice(0, suffixIndex + 1);
      } else {
        // If no suffix is present, format the number with a comma separator
        processedValue = processedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    setDisplayValue(processedValue);
  };

  const handleBlur = () => {
    let originalValue = displayValue.trim().replace(/,/g, "");
    let parsedValue = parseValue(originalValue);

    if (parsedValue.toString() === originalValue) {
      let formattedValue = originalValue;

      if (parsedValue >= 1000) {
        const suffixes = ["", "K", "M", "B", "T"];
        let suffixIndex = 0;

        while (parsedValue >= 1000 && suffixIndex < suffixes.length - 1) {
          parsedValue /= 1000;
          suffixIndex++;
        }

        const roundedValue = Math.round(parsedValue * 100) / 100;

        if (roundedValue !== parsedValue) {
          formattedValue = originalValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          formattedValue =
            parsedValue.toFixed(2).replace(/\.00$/, "") + suffixes[suffixIndex];
        }
      }

      setDisplayValue(formattedValue);
    } else {
      setDisplayValue(originalValue);
    }

    setFieldValue(name, parseValue(originalValue));
  };

  return (
    <>
      {label && <label>{label}</label>}
      {props.required ? <span className="text-danger"> *</span> : null}
      {showDescription ? (
        <Overlay popoverContent={description}>
          <i
            className={
              "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
            }
          />
        </Overlay>
      ) : null}
      {wholeNumber ? (
        <NumberInput
          className={
            getFieldCSSClasses(touched, error, props.isHideValidations) +
            " " +
            classes +
            `${type === "number" ? " noscroll" : ""}`
          }
          {...props}
          {...field}
          decimalScale={0}
          disabled={isReadOnly || disabled || field.disabled}
          allowNegative={!min}
          onChange={val => {}}
          prefix={prefix}
          value={
            (props?.name ? props.overrideDefaultValues?.[props?.name] : null) ||
            field.value
          }
          onKeyPress={e => {
            const numericValue = (e.target.value || "").replace(/[^0-9]/g, "");
            if (numericValue.length >= 16) {
              e.preventDefault();
            }
            handleEnterOnInputField(e);
          }}
          onValueChange={val => {
            if (props?.copyValueTo && props?.setOverrideDefaultValues) {
              props.setOverrideDefaultValues({
                ...(props?.overrideDefaultValues || {}),
                [props.copyValueTo]: val.floatValue
              });
            }
            setFieldValue(
              name,
              val.floatValue === undefined ? "" : val.floatValue
            );
          }}
          onFocus={(event) => handleDefaultZeroOnInputField(event, field, type, defaultValue, props)}
        />
      ) : (props?.tags || []).find(tag => tag.code === "ShortenLongAmount") ? (
        <input
          className={
            getFieldCSSClasses(touched, error, props.isHideValidations) +
            " " +
            classes +
            `${type === "number" ? " noscroll" : ""}`
          }
          {...props}
          {...field}
          disabled={isReadOnly || disabled || field.disabled}
          type="text"
          value={"PKR " + displayValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onFocus={(event) => handleDefaultZeroOnInputField(event, field, type, defaultValue, props)}
          onKeyDown={handleEnterOnInputField}
        />
      ) : (
        <NumberInput
          className={
            getFieldCSSClasses(touched, error, props.isHideValidations) +
            " " +
            classes +
            `${type === "number" ? " noscroll" : ""}`
          }
          {...props}
          {...field}
          disabled={isReadOnly || disabled || field.disabled}
          allowNegative={!min}
          onChange={val => {}}
          prefix={prefix}
          decimalScale={decimalScale || undefined}
          value={
            (props?.name ? props.overrideDefaultValues?.[props?.name] : null) ||
            field.value
          }
          onKeyPress={e => {
            const numericValue = (e.target.value || "").replace(/[^0-9]/g, "");
            if (numericValue.length >= 16) {
              e.preventDefault();
            }
            handleEnterOnInputField(e);
          }}
          onValueChange={val => {
            if (props?.copyValueTo && props?.setOverrideDefaultValues) {
              props.setOverrideDefaultValues({
                ...(props?.overrideDefaultValues || {}),
                [props.copyValueTo]: val.floatValue
              });
            }
            setFieldValue(
              name,
              val.floatValue === undefined ? "" : val.floatValue
            );
          }}
          onFocus={(event) => handleDefaultZeroOnInputField(event, field, type, defaultValue, props)}
        />
      )}

      {props.mathematicalExpression?.formula ? (
        <>
          <button
            onClick={() => setShowComputationDetails(true)}
            className="formula-icon"
          >
            <Overlay popoverContent="View Computations">
              <i className="fas fa-calculator" />
            </Overlay>
          </button>

          <FormulaViewer
            show={showComputationDetails}
            onHide={() => setShowComputationDetails(false)}
            mathematicalExpression={props.mathematicalExpression}
            label={label}
          />
        </>
      ) : null}

      {!props.isHideValidations && withFeedbackLabel && touched && error && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type={type}
        />
      )}
    </>
  );
}
