import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { useHtmlClassService } from "../../../../_metronic/layout";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { getNavbarModules } from "../../../_helpers";
import { AnimateLoading } from "../../../../_metronic/_partials/controls";
import { Topbar } from "./Topbar";

import * as usersActions from "../../../../app/modules/Identity/_redux/users/usersActions";
import * as groupsActions from "../../../../app/modules/Identity/_redux/groups/groupsActions";
import * as modulesActions from "../../../../app/modules/Identity/_redux/modules/modulesActions";
import { fetchRecords } from "../../../../app/modules/MasterData/_redux/records/recordsActions";

export function Header() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user,
    impersonateUser,
    allModules,
    permissionsDictionary
  } = useSelector(
    state => ({
      user: state.auth.user,
      impersonateUser: state.users.impersonateUser,
      allModules: state.modules.allModules,
      permissionsDictionary: state.groups.permissionsDictionary
    }),
    shallowEqual
  );
  const location = useLocation();
  const testObj = localStorage.getItem("ImpersonateUser");
  const testUser = JSON.parse(testObj);
  // Users Redux state
  useEffect(() => {
    groupsActions.clearPermissionsDictionary();

    if (!impersonateUser?.code && testUser?.code) {
      const code = impersonateUser?.code || testUser?.code;
      dispatch(usersActions.fetchImpersonateUser(code, testUser?.category));
    }
    dispatch(
      usersActions.fetchUsersMinimals({
        pageNumber: 1,
        pageSize: 2000
      })
    );
    dispatch(
      modulesActions.fetchModules({
        paginationOverride: true,
        category: 0,
        isHeader: true
      })
    );

    dispatch(
      fetchRecords({
        paginationOverride: true,
        entityCode: "allowedProductCascade"
      })
    );

    dispatch(
      fetchRecords({
        paginationOverride: true,
        entityCode: "allowedProductsNewCascade"
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!testUser && !impersonateUser && user.groupCodes) {
      dispatch(groupsActions.fetchLoggedInUserGroups());
    } else if (impersonateUser) {
      const groupCodes = impersonateUser?.groupCodes || testUser?.groupCodes;
      dispatch(
        groupsActions.fetchGroupsByCodesList({
          isDetailRequired: true,
          groupCodes,
          category: 0
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, impersonateUser]);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  useEffect(() => {
    let redirectPath = localStorage.getItem("OriginalUserPath");
    if (
      impersonateUser &&
      testUser &&
      allModules &&
      permissionsDictionary &&
      location?.pathname === redirectPath
    ) {
      let navBarModules = getNavbarModules(allModules, permissionsDictionary);
      history.push(navBarModules?.[0]?.route || "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allModules, permissionsDictionary, impersonateUser]);

  const removeImpersonateUser = () => {
    localStorage.removeItem("ImpersonateUser");
    dispatch(usersActions.fetchImpersonateUser());
    let redirectPath = localStorage.getItem("OriginalUserPath");
    history.push(redirectPath);
    localStorage.removeItem("OriginalUserPath");
  };

  return (
    <div className="position-fixed top-0 zindex-5 w-100">
      {/*begin::Header*/}
      {impersonateUser && (
        <div className="desktop-test-mode">
          You are in Test Mode,{" "}
          <span
            onClick={removeImpersonateUser}
            className="font-weight-boldest cursor-pointer"
          >
            {" "}
            <i className="fa fa-redo text-light font-size-sm mr-1" />
            Switch to {user?.name}
          </span>{" "}
        </div>
      )}

      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </div>
  );
}
