/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import {OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  inputTypesDictionary,
  queryBuilderQueryTypeEnums
} from "../../../../../../_custom/_helpers/staticFields";

export const ActionsFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onEdit,
    onClone,
    onDelete,
    onView,
    onCopyToRoot,
    openedRow,
    setOpenedRow,
    mode,
    isReadOnly,
    isShowCopyButton,
    shouldHideActionFormatter,
    gridData,
    deleteCustomerRow,
    setDeleteCustomerRow,
    hideActionsOnCustomerRow,
    setHideActionsOnCustomerRow,
    checkTagBasedPermission,
    isShowDeletedGridRows,
    parameters: {
      identifier,
      dataType,
      masterDataType,
      allRecords,
      field,
      customers
    }
  }
) => {
  const priorityOverlayRef = React.createRef();
  const toggleOverlay = () => priorityOverlayRef.current?.click();

  const popover = (
    <Popover id="name-popover">
      <Popover.Content className="p-0">
        <div className="pl-0">
          <button
            onClick={() => {
              toggleOverlay();
              onView(row.code, row, rowIndex);
            }}
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Join-1.svg")}
                title={""}
              />
            </span>
            View
          </button>
          {mode !== "view" && !isShowDeletedGridRows? (
            <>
              {checkTagBasedPermission("edit") ? (
                <button
                  onClick={() => {
                    toggleOverlay();
                    onEdit(row.code, row, rowIndex);
                  }}
                  className="dropdown-item mr-2"
                >
                  <span className="svg-icon svg-icon-md svg-icon-success mr-2">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                      title={"Edit"}
                    />
                  </span>
                  Edit
                </button>
              ) : null}
              {hideActionsOnCustomerRow && !isShowDeletedGridRows? (
                <>
                  {gridData?.length !== customers?.length &&
                  checkTagBasedPermission("clone") ? (
                    <button
                      onClick={() => {
                        toggleOverlay();
                        onClone(row.code, row, rowIndex);
                      }}
                      className="dropdown-item mr-2"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-success mr-2">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Duplicate.svg"
                          )}
                          title={"Clone"}
                        />
                      </span>
                      Clone
                    </button>
                  ) : null}
                  {rowIndex === gridData?.length - 1 &&
                  checkTagBasedPermission("delete") && !isShowDeletedGridRows? (
                    <>
                      <button
                        onClick={() => {
                          toggleOverlay();
                          onDelete(row.code, row, rowIndex);
                        }}
                        className="dropdown-item mr-2"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger mr-2">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                            title={"Delete"}
                          />
                        </span>
                        <span>Delete</span>
                      </button>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {checkTagBasedPermission("clone") ? (
                    <button
                      onClick={() => {
                        toggleOverlay();
                        onClone(row.code, row, rowIndex);
                      }}
                      className="dropdown-item mr-2"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-success mr-2">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Duplicate.svg"
                          )}
                          title={"Clone"}
                        />
                      </span>
                      Clone
                    </button>
                  ) : null}
                  {checkTagBasedPermission("delete") ? (
                    <button
                      onClick={() => {
                        toggleOverlay();
                        onDelete(row.code, row, rowIndex);
                      }}
                      className="dropdown-item mr-2"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger mr-2">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                          title={"Delete"}
                        />
                      </span>
                      <span>Delete</span>
                    </button>
                  ) : null}
                </>
              )}
            </>
          ) : null}

          {isShowCopyButton ? (
            <button
              onClick={() => {
                toggleOverlay();
                onCopyToRoot(row.code, row, rowIndex);
              }}
              className="dropdown-item mr-2"
            >
              <span className="svg-icon svg-icon-md svg-icon-grey mr-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Substract.svg")}
                  title={""}
                />
              </span>
              <span>Copy to Form</span>
            </button>
          ) : null}
        </div>
      </Popover.Content>
    </Popover>
  );
  let displayData = row[identifier] || "";
  let title = displayData;
  let fieldType = inputTypesDictionary[dataType]?.type;

  if (["select", "multiselect"].includes(fieldType)) {
    let allFieldRecords =
      allRecords?.[field?.code]?.records ||
      allRecords?.[masterDataType]?.recordsForGrid ||
      allRecords?.[masterDataType]?.records ||
      [];
    if (fieldType === "select")
      displayData = allFieldRecords.find(rec => rec.code === cellContent)?.name;
    else if (fieldType === "multiselect") {
      let filteredRecords = allFieldRecords.filter(
        rec => rec.code === cellContent
      );
      displayData = filteredRecords.map(
        (eachRec, index) =>
          `${eachRec.name} ${index < filteredRecords.length ? "," : ""}`
      );
    }
  } else if (typeof displayData === "object") {
    displayData = displayData.toString();
  }
  if (fieldType === "datePicker") {
    displayData =
      displayData && moment(displayData)?.isValid()
        ? moment(displayData)
            .format("LL")
            ?.toString()
        : "";
  }

  if (fieldType === "conditionBuilder") {
    displayData =
      (cellContent?.representations || []).find(
        rep => rep.representationType === queryBuilderQueryTypeEnums["HR"]
      )?.representationValue || "N/A";
  }

  if (fieldType === "richTextBox") {
    displayData = ReactHtmlParser(cellContent || "N/A");
      const tempElement = document.createElement('div');
      tempElement.innerHTML = cellContent || '';
      const textContent = tempElement?.textContent || tempElement?.innerText || '';
      title = textContent?.trim() || "N/A";
      displayData = textContent?.trim() || "N/A";
  }

  if (field?.tags?.find(tg => tg === "Customers")) {
    displayData = (customers || [])?.find(
      cus => cus?.cnic === cellContent?.replaceAll("-", "")
    )?.name;
    setHideActionsOnCustomerRow(true);
  }

  return (
    <>
      <span
        className={`font-weight-bold ${
          fieldType === "richTextBox" ? "comment-text" : null
        }`}
      >
          <OverlayTrigger overlay={
              <Tooltip id={title || displayData}>
                  {title || displayData}
              </Tooltip>
          }
          >
              <span>{displayData || "N/A"}</span>
          </OverlayTrigger>

      </span>

        {shouldHideActionFormatter !== true && (
        <OverlayTrigger
          rootClose
          trigger="click"
          onExit={() => {
            // if (openedRow === rowIndex) {
            //   setOpenedRow(null)
            // }
          }}
          overlay={popover}
          placement="top"
        >
          <a
            className={`${
              openedRow === rowIndex ? "btn-light" : "overlay-menu-action"
            } btn btn-xsm btn-hover-light float-right`}
            ref={priorityOverlayRef}
            onClick={() => {
              setOpenedRow(rowIndex);
            }}
          >
            <MoreVertIcon />
          </a>
        </OverlayTrigger>
      )}
    </>
  );
};
