import React, { useEffect, useState } from "react";
import { Overlay } from "./Overlay";

export function Switch(props) {
  const {
    classNames,
    label,
    name,
    disabled,
    isReadOnly,
    WorkflowStagesClass,
    required,
    isRequired,
    field: { value, onChange },
    onError,
    showDescription,
    description
  } = props;

  const [isErrorPropagated, setErrorPropagated] = useState(false);

  const onChangeSwitch = e => {
    let { target } = e;
    target.value = target.checked;
    if ((required || isRequired) && !target.checked)
      onError(name, name + " is required");
    else if (target.checked && isErrorPropagated) setErrorPropagated(false);
    onChange(e);
  };

  useEffect(() => {
   if (
      (!value || value === "False") &&
      (required || isRequired) &&
      !isErrorPropagated
    ) {
      onError(name, name + " is required");
      setErrorPropagated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, required, isRequired]);

  // const formatLabel = label => {
  //   const maxLength = 37; // Maximum characters before truncating
  //   if (label.length > maxLength) {
  //     return `${label.substr(0, maxLength)}...`;
  //   }
  //   return label;
  // };

  return (
    <>
      <div className={`custom-checkbox ${WorkflowStagesClass}`}>
        <div className="checkbox-inline align-items-center d-flex justify-content-between">
          <label
            className={`checkbox checkbox-rounded m-0 ${classNames} ${
              isReadOnly || disabled ? "checkbox-disabled" : ""
            } `}
            title={label}
          >
            <input
              name={name}
              id={name}
              type="checkbox"
              disabled={isReadOnly || disabled}
              checked={["True", "true", true].includes(value) || false}
              onChange={onChangeSwitch}
            />
            <span />
            {label}{" "}
          </label>
          <div className="d-flex align-items-center mr-2">
            {required || isRequired ? (
              <p className="text-danger m-0"> *</p>
            ) : null}
            {showDescription && (
              <Overlay popoverContent={description}>
                <i
                  className={
                    "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right top-0"
                  }
                />
              </Overlay>
            )}
          </div>
        </div>
      </div>
      {(required || isRequired) &&
      (value === "False" || !value) &&
      !props.isHideValidations ? (
        <div className="invalid-feedback d-block mt-n6">
          {label} is required
        </div>
      ) : null}
    </>
  );
}
