import { createSlice } from "@reduxjs/toolkit";

const initialCollectionsState = {
  listLoading: false,
  actionsLoading: false,
  account: null,
  accounts: null,
  totalCount: 0,
  statement: null,
  statements: null,
  statementsTotalCount: 0,
  activities: null,
  lastError: null,
  defaultTab: "overview",
  defaultFilters: null,
  resetActivityLogForm: false,
  customerAccounts: undefined,
  balanceList: undefined,
  installmentsPlan: null,
  installmentsPlanCount: 0,
  filterData: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: "collections",
  initialState: initialCollectionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.resetActivityLogForm = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      state.resetActivityLogForm = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    accountsFetched: (state, action) => {
      const { totalCount, accounts } = action.payload;
      state.listLoading = false;
      state.accounts = accounts;
      state.totalCount = totalCount;
      state.error = null;
      state.customerAccounts = null;
      state.balanceList = null;
      state.statements = null;
      state.statementsTotalCount = 0;
      state.installmentsPlan = null;
      state.installmentsPlanCount = 0;
    },
    accountFetched: (state, action) => {
      state.actionsLoading = false;
      state.account = action.payload || {};
      state.error = null;
      state.customerAccounts = null;
      state.balanceList = null;
      state.installmentsPlan = null;
      state.installmentsPlanCount = 0;
    },
    activitiesFetched: (state, action) => {
      state.actionsLoading = false;
      state.activities = action.payload?.logs || [];
      state.error = null;
    },
    activityLogCreated: (state, action) => {
      state.actionsLoading = false;
      state.activities = action.payload?.logs || [];
      state.defaultTab = "history";
      state.resetActivityLogForm = true;
      state.error = null;
    },
    referenceCreated: (state, action) => {
      state.actionsLoading = false;
      state.account = action.payload || {};
      state.error = null;
    },
    tabSwitched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.defaultTab = action.payload.code;
      state.defaultFilters = action.payload.filters;
    },
    statementsFetched: (state, action) => {
      const { totalCount, statements } = action.payload;
      state.listLoading = false;
      state.statements = statements;
      state.statementsTotalCount = totalCount;
      state.error = null;
    },
    installmentsPlanFetched: (state, action) => {
      const { totalCount, installmentsPlan } = action.payload;
      state.listLoading = false;
      state.installmentsPlan = installmentsPlan;
      state.installmentsPlanCount = totalCount;
      state.error = null;
    },
    accountAssigned: (state, action) => {
      state.actionsLoading = false;
    },
    customerAccountsFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.customerAccounts = action?.payload?.accountSummaryList;
      state.balanceList = null;
    },
    customerBalanceFetched: (state, action) => {
      let balance = {
        acctNo: action?.payload?.acctNo,
        balance: action?.payload?.acctAvailBal
      };
      state.balanceList = {
        ...state.balanceList,
        [action?.payload?.acctNo]: balance
      };
      state.actionsLoading = false;
      state.error = null;
    },
    clearList: state => {
      state.accounts = null;
      state.totalCount = 0;
    },
    setData: (state, action) => {
      state.filterData = action?.payload;
    }
  }
});
