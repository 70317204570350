/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_metronic/_partials/dropdowns";
import {
  generateAcronym,
  imageExists,
  passwordHelper
} from "../../../../_helpers";
import * as modulesActions from "../../../../../app/modules/Identity/_redux/modules/modulesActions";
import * as groupActions from "../../../../../app/modules/Identity/_redux/groups/groupsActions";
import { Logout } from "../../../../../app/modules/Auth/_redux/authCrud";
import * as recordsActions from "../../../../../app/modules/MasterData/_redux/records/recordsActions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { changePassword } from "../../../../../app/modules/Identity/_redux/users/usersActions";
import sha256 from "crypto-js/sha256";
import { enc } from "crypto-js";
import { successNotification } from "../../../../_partials/notifications";
import { Dialog } from "../../../../_partials/Dialog";
import ReactHtmlParser from "react-html-parser";
import SVG from "react-inlinesvg";
import { TestUserModal } from "../../../../../app/modules/ErrorHandler/TestUserModal";

const initialValues = {
  currentPassword: "",
  newPassword: ""
};

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("Required field"),
  currentPassword: Yup.string().required("Required field")
});
export function UserProfileDropdown() {
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [show, setShow] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [schema, setSchema] = useState(ChangePasswordSchema);
  const [notification, setNotification] = useState([]);
  const [userShow, setUserShow] = useState(false);
  const [testUserShow, setTestUserShow] = useState(false);
  const [avatar, setAvatar] = useState(null);
  // const [expirationTime, setExpirationTime] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user,
    uniqueIdentifier,
    userAuth,
    impersonateUser
    // expiresin
  } = useSelector(
    state => ({
      user: state.auth,
      uniqueIdentifier: state.auth.uniqueIdentifier,
      userAuth: state.auth?.user,
      impersonateUser: state.users.impersonateUser
      // expiresin: state.auth.expiresin
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   const storedExpirationTime = localStorage.getItem("expirationTime");
  //   if (storedExpirationTime) {
  //     setExpirationTime(new Date(storedExpirationTime));
  //   } else {
  //     const currentDateTime = new Date();
  //     const expirationDateTime = new Date(
  //       currentDateTime.getTime() + expiresin * 1000
  //     );
  //     setExpirationTime(expirationDateTime);
  //     localStorage.setItem("expirationTime", expirationDateTime);
  //   }
  // }, [expiresin]);

  // const formatTime = time => {
  //   const hours = time.getHours();
  //   const minutes = time.getMinutes();
  //   const period = hours >= 12 ? "PM" : "AM";
  //   const formattedHours = hours % 12 || 12;
  //   const formattedMinutes = minutes.toString().padStart(2, "0");
  //   return `${formattedHours}:${formattedMinutes} ${period}`;
  // };

  const passwordPolicy = useSelector(
    state => state.records?.["pass_policy"]?.records || null
  );
  const passwordPolicyLoading = useSelector(
    state => state.records?.["pass_policy"]?.listLoading || null
  );

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    dispatch(modulesActions.clearModulesPermissions());
    dispatch(groupActions.clearGroupsPermissions());
    Logout(user?.user?.id, uniqueIdentifier).then(params => {
      if (params.status === 200) {
        history.push("/logout");
      }
    });
  };

  const onHide = () => {
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShow(false);
  };

  const location = useLocation();
  useEffect(() => {
    if (!passwordPolicy || passwordPolicy?.[0]?.type === 1) {
      if (!location?.pathname?.split("/")?.includes("pass_policy")) {
        dispatch(
          recordsActions.fetchRecords({
            entityCode: "pass_policy",
            paginationOverride: true
          })
        ).then(res => {
          if (res?.status === 200) {
            let helperText = res?.data?.items?.[0]?.values?.helperText;
            let minLength = res?.data?.items?.[0]?.values?.min_length;
            let maxLength = res?.data?.items?.[0]?.values?.max_length;
            let helperTextUpdated = passwordHelper(
              helperText,
              minLength,
              maxLength
            );
            setHelperText(helperTextUpdated || "");
            let schema = Yup.object().shape({
              newPassword: Yup.string()
                .min(minLength, `Minimum ${minLength} characters`)
                .max(maxLength, `Maximum ${maxLength} characters`)
                .required("Required Field"),
              currentPassword: Yup.string()
                .min(minLength, `Minimum ${minLength} characters`)
                .max(maxLength, `Maximum ${maxLength} characters`)
                .required("Required Field")
            });
            setSchema(schema);
          }
        });
      }
    } else {
      let helperText = passwordPolicy?.[0]?.values?.helperText;
      let minLength = passwordPolicy?.[0]?.values?.min_length;
      let maxLength = passwordPolicy?.[0]?.values?.max_length;
      let helperTextUpdated = passwordHelper(helperText, minLength, maxLength);
      setHelperText(helperTextUpdated || "");
      let schema = Yup.object().shape({
        newPassword: Yup.string()
          .min(minLength, `Minimum ${minLength} characters`)
          .max(maxLength, `Maximum ${maxLength} characters`)
          .required("Required Field"),
        currentPassword: Yup.string()
          .min(minLength, `Minimum ${minLength} characters`)
          .max(maxLength, `Maximum ${maxLength} characters`)
          .required("Required Field")
      });
      setSchema(schema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, passwordPolicy]);

  useEffect(() => {
    if (!avatar) {
      let tempAvatar;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tempAvatar = (
        <div className="symbol-label">
          {generateAcronym(userAuth.name || "")}
        </div>
      );

      if (userAuth.gender) {
        imageExists(
          toAbsoluteUrl(`/media/users/${userAuth?.gender}-avatar.jpg`)
        ).then(response => {
          if (response) {
            setAvatar(
              <img
                className="bg-light"
                src={toAbsoluteUrl(
                  `/media/users/${userAuth?.gender}-avatar.jpg`
                )}
                alt={generateAcronym(userAuth.name || "")}
              />
            );
          } else {
            setAvatar(tempAvatar);
          }
        });
      } else {
        setAvatar(tempAvatar);
      }
    }
  }, [avatar, userAuth]);

  useEffect(() => {
    if (user?.passwordExpiryCounter <= 10 && !notification?.length) {
      let data = {
        code: "passwordExpiryCounter",
        type: "warning",
        message: `Please change your password`,
        subText: `Expires in ${user?.passwordExpiryCounter} days`
      };
      setNotification([...notification, data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.passwordExpiryCounter]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      dispatch(
        changePassword({
          oldPassword: sha256(values?.currentPassword).toString(enc.Base64),
          newPassword: values?.newPassword,
          uniqueIdentifier
        })
      ).then(res => {
        if (res?.status === 200) {
          setSubmitting(false);
          disableLoading();
          setShow(false);
          successNotification("Password changed successfully");
          formik.resetForm();
          logoutClick();
        } else {
          formik.resetForm();
          setSubmitting(false);
          disableLoading();
        }
      });
    }
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <>
      <Dropdown
        drop="down"
        alignRight
        show={userShow}
        onToggle={isOpen => {
          if (impersonateUser) {
            setTestUserShow(true);
          } else {
            setUserShow(isOpen);
          }
        }}
      >
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
          >
            <>
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                Hi,
              </span>

              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {impersonateUser?.name || userAuth.name}
              </span>
              <span className="symbol symbol-35 symbol-light-primary">
                <span className="symbol-label font-size-h5 font-weight-bold pulse pulse-primary">
                  {generateAcronym(
                    impersonateUser?.name || userAuth.name || ""
                  )}
                  <span className="pulse-ring"></span>
                </span>
              </span>
            </>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}

          <div className="offcanvas-header d-flex align-items-center justify-content-between p-5">
            <h4 className="font-weight-bold m-0">User Profile</h4>
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary cursor-pointer"
              onClick={() => {
                setUserShow(false);
              }}
            >
              <i className="ki ki-close icon-xs text-muted" />
            </span>
          </div>
          <div className="separator separator-solid"></div>

          <div className="d-flex align-items-center p-5">
            <div className="symbol symbol-50 mr-2">
              {avatar}

              <i className="symbol-badge bg-success" />
            </div>
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {userAuth.name}
              </a>
              <div className="navi">
                <a className="navi-item cursor-pointer">
                  <span className="navi-link p-0">
                    <span className="navi-icon">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                          title={"mail"}
                        ></SVG>
                      </span>
                    </span>
                    <span
                      className="navi-text text-muted font-size-xs"
                      style={{ width: "20rem", fontSize: "0.9rem" }}
                    >
                      {userAuth?.emailAddress}
                    </span>
                  </span>
                </a>
              </div>
              {/*<div className="navi">*/}
              {/*  <a className="navi-item cursor-pointer">*/}
              {/*    <span className="navi-link p-0 pb-2">*/}
              {/*      <span className="navi-icon">*/}
              {/*        <span className="svg-icon svg-icon-lg svg-icon-primary">*/}
              {/*          <SVG*/}
              {/*            src={toAbsoluteUrl(*/}
              {/*              "/media/svg/icons/Electric/Shutdown.svg"*/}
              {/*            )}*/}
              {/*            title={""}*/}
              {/*            // src={toAbsoluteUrl("/media/svg/icons/Home/Timer.svg")}*/}
              {/*          ></SVG>*/}
              {/*        </span>*/}
              {/*      </span>*/}
              {/*      <span*/}
              {/*        className="navi-text text-muted font-size-xs"*/}
              {/*        style={{ width: "20rem", fontSize: "0.9rem" }}*/}
              {/*      >*/}
              {/*        Session expire at:{" "}*/}
              {/*        {expirationTime && formatTime(expirationTime)}*/}
              {/*      </span>*/}
              {/*    </span>*/}
              {/*  </a>*/}
              {/*</div>*/}
              {/*<a*/}
              {/*  href="#"*/}
              {/*  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"*/}
              {/*>*/}
              {/*  Token ExpireTime: {expirationTime && formatTime(expirationTime)}*/}
              {/*</a>*/}
            </div>
          </div>

          {notification?.length ? (
            <>
              <div className="separator separator-solid"></div>
              <div className="p-5">
                <h5 className="mb-2 mt-5">Notifications</h5>
                {(notification || []).map(item => {
                  return (
                    <div
                      className={`d-flex align-items-center bg-light-${item?.type} rounded p-5 gutter-b`}
                    >
                      <span
                        className={`svg-icon svg-icon-lg svg-icon-${item?.type} mr-5`}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Warning-1-circle.svg"
                          )}
                          title={""}
                          className="svg-icon svg-icon-lg"
                        ></SVG>
                      </span>
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a
                          href="#"
                          onClick={() => setShow(true)}
                          className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                        >
                          {item?.message}
                        </a>
                        <span className="text-muted font-size-sm">
                          {item?.subText}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}

          <div className="separator separator-solid"></div>
          <div className="p-5 d-flex justify-content-end">
            <button
              className="btn btn-light-danger btn-bold btn-sm mr-1"
              onClick={logoutClick}
            >
              Sign out
            </button>
            {process.env?.REACT_APP_USE_ACTIVE_DIRECTORY !== "true" ? (
              <button
                className="btn btn-light-warning btn-bold btn-sm"
                onClick={() => {
                  setUserShow(false);
                  setShow(true);
                }}
              >
                Change Password
              </button>
            ) : null}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Dialog
        size={"md"}
        isCentered={false}
        show={show}
        onHide={onHide}
        backdrop={false}
        modalTitle={"Change Password"}
        modalBody={
          <>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Current Password*"
                  type={showCurrentPassword ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-5 px-6  override-padding-right-eye  ${getInputClasses(
                    "currentPassword"
                  )}`}
                  name="currentPassword"
                  {...formik.getFieldProps("currentPassword")}
                />

                <button
                  hidden={!formik?.values?.currentPassword}
                  type="button"
                  onClick={() => {
                    setShowCurrentPassword(!showCurrentPassword);
                  }}
                  className={"float-right password-eye"}
                >
                  {!showCurrentPassword ? (
                    <i className="fa fa-eye-slash" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-eye" aria-hidden="true" />
                  )}
                </button>

                {formik.touched.currentPassword &&
                formik.errors.currentPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.currentPassword}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password*"
                  className={`form-control form-control-solid h-auto py-5 px-6  override-padding-right-eye  ${getInputClasses(
                    "newPassword"
                  )}`}
                  name="newPassword"
                  {...formik.getFieldProps("newPassword")}
                />

                <button
                  hidden={!formik?.values?.newPassword}
                  type="button"
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                  }}
                  className={"float-right password-eye"}
                >
                  {!showNewPassword ? (
                    <i className="fa fa-eye-slash" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-eye" aria-hidden="true" />
                  )}
                </button>

                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newPassword}
                    </div>
                  </div>
                ) : null}
              </div>

              {helperText && (
                <div className="mt-5 ml-0 alert alert-custom alert-light-warning alert-dismissible">
                  <div className="alert-text font-weight-bold ml-n5">
                    {ReactHtmlParser(helperText || "")}
                  </div>
                </div>
              )}

              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  type="button"
                  onClick={() => {
                    formik.resetForm();
                    onHide();
                  }}
                  id="kt_login_forgot_cancel"
                  className="btn btn-light btn-md font-weight-bold my-2 mx-2"
                >
                  Cancel
                </button>
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary btn-md font-weight-bold my-2 mx-2"
                  disabled={
                    formik.isSubmitting ||
                    passwordPolicyLoading ||
                    !formik.isValid
                  }
                >
                  Change Password
                  {loading && (
                    <span className="ml-2 pr-5 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </form>
          </>
        }
        noFooter={true}
        modalBodyClasses={"text-justify"}
      />

      <TestUserModal
        show={testUserShow}
        onHide={() => setTestUserShow(false)}
      />
    </>
  );
}
