/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

// packages
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { cloneDeep, debounce } from "lodash";

// custom components
import {
  CodeInput,
  ColorPicker,
  DatePickerField,
  DualListBox,
  FieldFeedbackLabel,
  Input,
  MaskedInput,
  NumberFormatter,
  Overlay,
  Phone,
  ReactCreatableSelect,
  ReactSelect,
  ReactSelectControlled,
  ReactSelectDraggable,
  RichTextBox,
  SkeletonComponent,
  Switch,
  TextArea
} from "../../../../_custom/_partials/controls";
// functions
import {
  findJSONQuery,
  getFieldsWithValidation,
  getOutcomeValueInDesiredFormat,
  getRestrictions,
  IsJsonString,
  parseDropdownOptionsToRecords,
  passwordHelper,
  removeEmptyParameters,
  removeNullValuesFromData,
  removeWhitespaces,
  setDefaultValues,
  validateColorCode,
  validateJSONLogic
} from "../../../_helpers";
import {
  dropDownOptions,
  queryBuilderDefaultExpressions,
  queryBuilderQueryTypeEnums
} from "../../../_helpers/staticFields";
import {
  hideFieldsInEntity,
  inputTypes
} from "../../../_helpers/commonStaticVariables";
import { DocumentUploadInput } from "./DocumentUploadInput";
import ListFieldGrid from "../../../../app/modules/Workflows/pages/Form/Components/ListFieldGrid";
import { ApplicationDynamicFormatter } from "../../grids/column-formatters";
import { fetchRecords } from "../../../../app/modules/MasterData/_redux/records/recordsActions";
import { Utils as QbUtils } from "react-awesome-query-builder";
import { QueryBuilder } from "./QueryBuilder";
import { MathematicalExpression } from "./MathematicalExpression";
import { fieldLayoutTitles } from "../../grids/UIHelpers";
import ReactHtmlParser from "react-html-parser";
import { errorNotification, warningNotification } from "../../notifications";
import { IconPickerField } from "./IconPickerField";

const dropdownFieldIds = inputTypes
  .filter(inputType => ["select", "multiselect"].includes(inputType.type))
  .map(field => field.id);

const dropdownListIds = inputTypes
  .filter(inputType => ["list"].includes(inputType.type))
  .map(field => field.id);

const getHumanReadableQuery = (tree, config) =>
  QbUtils.queryString(tree, config, true);
const getSQLQuery = (tree, config) => QbUtils.sqlFormat(tree, config);
const getMongoDBQuery = (tree, config) => QbUtils.mongodbFormat(tree, config);
const getExpressionTreeQuery = (tree, config) =>
  QbUtils.queryString(tree, config);
const getJSONLogicQuery = (tree, config) =>
  JSON.stringify(QbUtils.jsonLogicFormat(tree, config)?.logic || {});

const AddEditViewForm = props => {
  let { mode: viewType } = useParams();
  const dispatch = useDispatch();

  const {
    workflowsState,
    detailedFieldsConfig,
    fieldsConfig,
    recordsState,
    fieldsDictionary,
    tags,
    passwordPolicy,
    user
  } = useSelector(
    state => ({
      detailedFieldsConfig: state.fields?.fieldsWithDetails,
      fieldsDictionary: state.fields?.fieldsDictionary,
      fieldsConfig: state.fields?.entities,
      recordsState: state.records,
      tags: state.records?.tags?.records,
      workflowsState: state.workflows,
      passwordPolicy: state.records?.["pass_policy"]?.records || null,
      user: state.auth
    }),
    shallowEqual
  );
  const customers = workflowsState.customers || [];
  if (props.overrideMode) viewType = props.overrideMode;

  const [formValues, setFormValues] = useState(
    removeNullValuesFromData(props.fieldsValues)
  );

  const [fieldsConfiguration, setFieldsConfiguration] = useState(
    getFieldsWithValidation(props.fieldsList, viewType)
  );
  const [renderableSkeletons, setRenderableSkeletons] = useState(undefined);
  const [renderableFields, setRenderableFields] = useState(undefined);
  // const [tagsDictionary, setTagsDictionary] = useState({});
  const [fieldsOriginalState, setFieldsState] = useState({});
  const [isValidatingForm, setIsValidatingForm] = useState(true);
  const [conditionsStatus, setConditionsStatus] = useState({});
  const [defaultValuesList, setDefaultValuesList] = useState({});
  const [defaultTypeStatic, setDefaultTypeStatic] = useState(true);
  const [clonedFieldList, setClonedFieldList] = useState(undefined);
  const [passwordConfiguration, setPasswordConfiguration] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (!tags?.length) {
      let masterDataType = "tags";
      dispatch(
        fetchRecords({ paginationOverride: true, entityCode: masterDataType })
      );
    }

    if (!passwordPolicy || passwordPolicy?.[0]?.type === 1) {
      if (!location?.pathname?.split("/")?.includes("pass_policy")) {
        dispatch(
          fetchRecords({
            paginationOverride: true,
            entityCode: "pass_policy"
          })
        ).then(res => {
          if (res?.status === 200) {
            let helperText = res?.data?.items?.[0]?.values?.helperText;
            let minLength = res?.data?.items?.[0]?.values?.min_length || 2;
            let maxLength = res?.data?.items?.[0]?.values?.max_length || 20;
            let helperTextUpdated = passwordHelper(
              helperText,
              minLength,
              maxLength
            );

            setPasswordConfiguration({
              helperText: helperTextUpdated || "",
              minLength,
              maxLength
            });
          }
        });
      }
    } else {
      let helperText = passwordPolicy?.[0]?.values?.helperText;
      let minLength = passwordPolicy?.[0]?.values?.min_length || 2;
      let maxLength = passwordPolicy?.[0]?.values?.max_length || 20;
      let helperTextUpdated = passwordHelper(helperText, minLength, maxLength);

      setPasswordConfiguration({
        helperText: helperTextUpdated || "",
        minLength,
        maxLength
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tags?.length) {
      let tempDictionary = {};

      (tags || []).forEach(tag => {
        tempDictionary[tag.code] = tag;
      });

      // setTagsDictionary(tempDictionary);
      setFieldsConfiguration(
        getFieldsWithValidation(props.fieldsList, viewType, tempDictionary)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  // if fields were provided and were not given initially, reset fields and skeletons, then set fields accordingly
  useEffect(() => {
    if (props.fieldsList) {
      if (!clonedFieldList) {
        let clone = cloneDeep(props.fieldsList);
        setClonedFieldList(clone);
      }
      let defaultValueField = props.fieldsList.find(
        field => field.code === "defaultValue"
      );
      if (defaultValueField) {
        if (defaultTypeStatic && props.fieldsValues["defaultValueType"] !== 1) {
          defaultValueField.masterDataType = props.defaultValueMasterDataType;
        } else {
          defaultValueField.masterDataType = "fields";
        }
      }
      setRenderableFields(undefined);
      setRenderableSkeletons(undefined);
      setFieldsConfiguration(
        getFieldsWithValidation(props.fieldsList, viewType)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.fieldsList,
    viewType,
    props.defaultValueMasterDataType,
    defaultTypeStatic,
    props.fieldsValues
  ]);

  // if field values are present (edit/view mode), update form values
  useEffect(() => {
    if (props.fieldsValues && Object.keys(props.fieldsValues)?.length) {
      Object.keys(props.fieldsValues).forEach(key => {
        if (!props.fieldsValues[key] && defaultValuesList[key]) {
          props.fieldsValues[key] = defaultValuesList[key];
        }

        // if (
        //   (inputTypesDictionary[props.fieldsValues.dataType]?.type === "creatableSelect" || inputTypesDictionary[props.fieldsValues.dataType]?.type === "multiselect") &&
        //   props.fieldsValues?.defaultValueType === 0
        // ) {
        //   if (typeof props.fieldsValues?.defaultValue === 'string') {
        //     let data = IsJsonString(props.fieldsValues?.defaultValue)
        //     props.fieldsValues.defaultValue = data
        //   }
        // }

        if (IsJsonString(props.fieldsValues[key])) {
          let data = JSON.parse(props.fieldsValues[key]);
          if (Array.isArray(data)) {
            props.fieldsValues[key] = data;
          }
        }
      });

      setFormValues(removeNullValuesFromData(props.fieldsValues));
    } else {
      //Check if the value is in String than after conversion it is in JSON format or not
      Object.keys(defaultValuesList).forEach(key => {
        if (IsJsonString(defaultValuesList[key])) {
          let data = JSON.parse(defaultValuesList[key]);
          if (Array.isArray(data)) {
            defaultValuesList[key] = data;
          }
        }
      });
      setFormValues(removeNullValuesFromData(defaultValuesList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fieldsValues, defaultValuesList]);

  // if skeleton prop changes, set skeletons accordingly
  useEffect(() => {
    // if (props.showSkeleton) mountSkeletons(fieldsConfiguration.fields);
    if (props.showSkeleton) mountSkeletons();
    else setRenderableSkeletons(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showSkeleton]);

  const mountSkeletons = () => {
    const fieldList = new Array(props.skeletonLength || 6).fill("");
    setRenderableFields(undefined);
    setRenderableSkeletons(
      fieldList.map((_, index) => (
        <SkeletonComponent
          key={index}
          rows={1}
          columns={1}
          skeletonClass={"col-lg-4 mb-8"}
        />
      ))
    );
  };

  // if dropdownOptions change
  useEffect(() => {
    // if dropdownOptions are available, remount fields to update dropdowns
    if (props.dropdownOptions) setRenderableFields(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dropdownOptions]);

  const removeDisabledInputValues = (formValues, setFieldValue) => {
    const dataTypeValue = formValues["dataType"];
    if (dataTypeValue === null || dataTypeValue === undefined) return;

    if (dataTypeValue < 2) {
      setFieldValue("masterDataType", null);
      setFieldValue("dependentField", "");
      return;
    } else if (dataTypeValue < 4) {
      setFieldValue("minLength", null);
      setFieldValue("maxLength", null);
      return;
    }
    setFieldValue("masterDataType", null);
    setFieldValue("dependentField", "");
    setFieldValue("minLength", null);
    setFieldValue("maxLength", null);
  };

  const getDropdownOptions = (masterDataType, dropDownType, code) => {
    if (dropDownType === "dualListBox") {
      return (
        props.dropdownOptions?.[masterDataType] ||
        fieldsDictionary?.[code]?.records ||
        []
      )
        .filter(
          option =>
            !hideFieldsInEntity.includes(option?.dataType) &&
            option?.isHidden !== true
        )
        .map(({ name, code, entityName, entityCode, type }) => ({
          label:
            (!props.hideCodeInDropdownLabel
              ? (entityCode || code) + ": "
              : "") +
            (props?.renamingDictionary?.[entityName] ||
              entityName ||
              props?.renamingDictionary?.[name] ||
              name),
          value: entityCode || code
        }));
    } else {
      return (
        props?.dropdownOptions?.[masterDataType] ||
        fieldsDictionary?.[code]?.records ||
        []
      )
        ?.filter(option => option?.isHidden !== true)
        .map(
          ({ name, code, entityName, entityCode, tooltip, type, values }) => ({
            label:
              (!props.hideCodeInDropdownLabel
                ? (entityCode || code) + ": "
                : "") +
              (props?.renamingDictionary?.[entityName] ||
                entityName ||
                props?.renamingDictionary?.[name] ||
                name),
            value: entityCode || code,
            tooltip,
            values: values
          })
        );
    }
  };

  const getDropdownSelectedValue = (options, selectedOption, dropdownType) => {
    if (selectedOption === null || selectedOption === undefined)
      return undefined;

    if (dropdownType === "single") {
      const selOption = options.find(item => item.value === selectedOption);
      return selOption || "undefined";
    }

    if (dropdownType === "multi") {
      // Checking for both type of values, if its array then dont split otherwise split.
      let tempSelectedOptions = Array.isArray(selectedOption)
        ? selectedOption
        : selectedOption?.split("!");
      const selOption = tempSelectedOptions.map(item =>
        options.find(opt => opt?.value === item)
      );
      return selOption?.filter(option => option !== "all");
    }

    return undefined;
  };

  const getDropdownComparisonValue = (prevState, currentState, dataType) => {
    let result = [];
    (prevState || []).forEach(value => {
      let detail = (props.dropdownOptions?.[dataType] || []).find(
        arr => arr?.code === value
      );
      if (currentState?.includes(value)) {
        result = [
          ...result,
          {
            value: value,
            label: detail?.code + ": " + detail?.name
          }
        ];
      } else {
        result = [
          ...result,
          {
            value: value,
            label: detail?.code + ": " + detail?.name,
            bgColor: "#FFE2E5",
            color: "#F64E60"
          }
        ];
      }
    });
    (currentState || []).forEach(value => {
      let ifAlreadyExist = (result || []).find(arr => arr?.value === value);
      if (!ifAlreadyExist) {
        let detail = (props.dropdownOptions?.[dataType] || []).find(
          arr => arr?.code === value
        );
        result = [
          ...result,
          {
            value: value,
            label: detail?.code + ": " + detail?.name,
            bgColor: "#C9F7F5",
            color: "#1BC5BD"
          }
        ];
      }
    });
    return result;
  };

  const getCreatedSelectValue = (fieldValue, options, isSingle) => {
    if (isSingle) {
      const selOption = options?.find(item => item.value === fieldValue);
      return selOption ? selOption : { label: fieldValue, value: fieldValue };
    } else {
      return fieldValue?.map(item => ({
        label: item,
        value: item
      }));
    }
  };

  const onChangeDropdown = (
    options,
    fieldCode,
    setFieldValue,
    formValues,
    valueAsString
  ) => {
    if (!options) {
      setFieldValue(fieldCode, options);

      return;
    }

    if (Array.isArray(options)) {
      if (valueAsString) {
        let selOptions = "";
        options.forEach(opt => (selOptions += opt.value + "!"));
        setFieldValue(fieldCode, selOptions);
      } else {
        const selOptions = options.map(item => item.value);
        setFieldValue(fieldCode, selOptions);
      }
    } else {
      setFieldValue(fieldCode, options.value);

      if (fieldCode === "dataType") {
        removeDisabledInputValues(
          { ...formValues, [fieldCode]: options.value },
          setFieldValue
        );
        // if the dataType value is one of the ids of dropdown types, set masterDataType of default value to null
        setFieldValue("defaultValue", "");
        if (!dropdownFieldIds.includes(options.value)) {
          props.setDefaultValueMasterDataType(null);
        }
      }

      if (fieldCode === "defaultValueType") {
        let editDefaultValueType = props.fieldsValues["defaultValueType"];
        let editDefaultValue = props.fieldsValues["defaultValue"];

        // setFieldValue("defaultValue", "");
        if (options.value === 1) {
          setDefaultTypeStatic(false);
        } else {
          setDefaultTypeStatic(true);
        }
        // if fieldValues has defaultValueType and defaultValue than setFieldValue according to option value
        if (options.value === editDefaultValueType && editDefaultValue) {
          setFieldValue("defaultValue", editDefaultValue);
        } else {
          setFieldValue("defaultValue", "");
        }
      }

      if (fieldCode === "masterDataType") {
        dispatch(
          fetchRecords({ entityCode: options?.value, paginationOverride: true })
        ).then(res => {
          props.setDefaultValueMasterDataType(options.value);
        });
      }
    }
  };

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };

  const isDropdownFieldMissingOption = (field, dropdownOptions) =>
    !dropdownOptions || (dropdownOptions && !dropdownOptions[field]);

  const onChangeQueryBuilder = (
    { tree, config },
    code,
    representations,
    formik
  ) => {
    const expressionTree = JSON.stringify(QbUtils.getTree(tree));

    const expressionValue = {
      expressionTree,
      representations: (representations || queryBuilderDefaultExpressions).map(
        rep => {
          if (rep === "Mongo")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getMongoDBQuery(tree, config)
            };
          else if (rep === "SQL")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getSQLQuery(tree, config)
            };
          else if (rep === "HR")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getHumanReadableQuery(tree, config)
            };
          else if (rep === "JSONLogic")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getJSONLogicQuery(tree, config)
            };
          else if (rep === "Tree")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getExpressionTreeQuery(tree, config)
            };
          else
            return {
              representationType: queryBuilderQueryTypeEnums["HR"],
              representationValue: getHumanReadableQuery(tree, config)
            };
        }
      )
    };

    formik.setFieldValue(
      code,
      Object.keys(QbUtils.getTree(tree)?.children1)?.length !== 0
        ? expressionValue
        : null
    );
  };

  const getRenderableFields = (formik, fieldList) => {
    // if fields have been calculated, return from here
    if (renderableFields) return renderableFields;

    let defaultValues = {};
    // calculate fields if not done before
    const fieldsToRender = fieldList.map((field, index) => {
      const {
        name,
        label,
        type,
        dataType,
        masterDataType,
        isRequired,
        disabled,
        hidden,
        isHidden,
        tags,
        code,
        minLength,
        maxLength,
        valueAsString,
        isReadOnly,
        description,
        defaultValue,
        subFields,
        regex,
        representations,
        isSingle,
        layout
      } = field;
      const inputType = inputTypes.find(input => input.id === dataType)?.type;
      const key = index + "-" + code;
      // const inputType = inputTypes[dataType].type;
      const hiddenOverride = tags?.find(tag => tag === "PasswordWithPolicy")
        ? !(
            viewType === "add" &&
            user?.user?.groupCodes?.includes("SetDefaultPasswordOnUserCreation")
          )
        : false;
      const restrictions = getRestrictions(
        code,
        {
          isRequired,
          disabled,
          hidden: hidden || isHidden || hiddenOverride,
          tags
        },
        viewType,
        false,
        props?.isFilter
      );
      defaultValues = {
        ...defaultValues,
        ...setDefaultValues(field, fieldList)
      };
      setDefaultValuesList({ ...defaultValues });

      switch (inputType) {
        case "text":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) =>
                  code === "code" ? (
                    <CodeInput
                      field={field}
                      form={form}
                      meta={meta}
                      label={label}
                      name={code}
                      type={type}
                      placeholder={"Enter " + (label || code)}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      isReadOnly={isReadOnly}
                      maxLength={maxLength}
                      onChange={field.onChange}
                      description={description}
                      showDescription={description?.length}
                      {...restrictions}
                    />
                  ) : tags?.find(
                      tag => tag.code === "maskedCNIC" || tag === "maskedCNIC"
                    ) ||
                    tags?.find(
                      tag => tag.code === "oldCNIC" || tag === "oldCNIC"
                    ) ? (
                    <MaskedInput
                      classes={code}
                      field={{ ...field }}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      form={form}
                      meta={meta}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      description={description}
                      showDescription={description?.length}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      setFieldValue={formik.setFieldValue}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      key={key}
                      {...restrictions}
                    />
                  ) : tags?.find(tag => tag === "ContactNumber") ||
                    tags?.find(tag => tag === "LandlineNumber") ? (
                    <Phone
                      classes={code}
                      field={{ ...field }}
                      prevState={props?.prevState ? props?.prevState : null}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      form={form}
                      meta={meta}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      description={description}
                      showDescription={description?.length}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      setFieldValue={formik.setFieldValue}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      key={key}
                      {...restrictions}
                    />
                  ) : (
                    <div className="input-container">
                      {tags?.find(tag => tag === "PasswordWithPolicy") ? (
                        <Overlay
                          popoverContent={
                            <>
                              {passwordConfiguration.helperText && (
                                <div className="mt-n3 mb-n3 ml-n4 mr-n4 alert alert-custom alert-light-warning alert-dismissible">
                                  <div className="alert-text font-size-lg font-weight-bold ml-n5 text-lg-left">
                                    {ReactHtmlParser(
                                      passwordConfiguration.helperText || ""
                                    )}{" "}
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        >
                          <i
                            className={
                              "fas fa-info-circle icon-nm text-hover-primary float-right"
                            }
                          />
                        </Overlay>
                      ) : null}
                      <Input
                        field={field}
                        prevState={props?.prevState ? props?.prevState : null}
                        form={form}
                        meta={meta}
                        isReadOnly={isReadOnly}
                        label={label}
                        name={code}
                        type={inputType}
                        tags={tags}
                        regex={regex}
                        placeholder={"Enter " + (label || code)}
                        withFeedbackLabel={true}
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={field.onChange}
                        description={description}
                        showDescription={description?.length}
                        defaultValue={defaultValue}
                        setFieldValue={formik.setFieldValue}
                        onBlur={e => {
                          let val =
                            typeof e?.target?.value === "string"
                              ? e?.target?.value.replace(/\s+/g, " ").trim()
                              : e?.target?.value;
                          formik.setFieldValue(code, val);
                        }}
                        {...restrictions}
                      />
                    </div>
                  )
                }
              </Field>
            </div>
          );

        case "number":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) =>
                  tags?.find(
                    tag => tag.code === "amount" || tag === "amount"
                  ) ||
                  tags?.find(
                    tag =>
                      tag.code === "FormattedNumber" ||
                      tag === "FormattedNumber"
                  ) ? (
                    <NumberFormatter
                      field={field}
                      form={form}
                      meta={meta}
                      label={label}
                      name={code}
                      type={inputType}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      placeholder={"Enter " + (label || code)}
                      withFeedbackLabel={true}
                      {...restrictions}
                      disabled={
                        restrictions.disabled ||
                        (["minLength", "maxLength"].includes(code) &&
                          !["0", "1", "14"].includes(form.values["dataType"]))
                      }
                      setFieldValue={formik.setFieldValue}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      description={description}
                      showDescription={description?.length}
                      prefix={
                        tags?.find(
                          tag => tag.code === "amount" || tag === "amount"
                        )
                          ? "PKR "
                          : ""
                      }
                    />
                  ) : (
                    <Input
                      field={field}
                      prevState={props?.prevState ? props?.prevState : null}
                      form={form}
                      meta={meta}
                      label={label}
                      isReadOnly={isReadOnly}
                      name={code}
                      type={inputType}
                      tags={tags}
                      placeholder={"Enter " + (label || code)}
                      withFeedbackLabel={true}
                      {...restrictions}
                      disabled={
                        restrictions.disabled ||
                        (["minLength", "maxLength"].includes(code) &&
                          ![0, 1, 14].includes(form.values["dataType"]))
                      }
                      onChange={field.onChange}
                      description={description}
                      showDescription={description?.length}
                      setFieldValue={formik.setFieldValue}
                    />
                  )
                }
              </Field>
            </div>
          );

        case "select":
          const options = getDropdownOptions(masterDataType, "", code);
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {label ? <label>{label}</label> : null}
                    {isRequired ? (
                      <span className={"text-danger"}> *</span>
                    ) : null}
                    {description?.length ? (
                      <Overlay popoverContent={description}>
                        <i
                          className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                          }
                        />
                      </Overlay>
                    ) : null}
                    {props.dependencyTree?.[code] ? (
                      <ReactSelectControlled
                        showSkeleton={isDropdownFieldMissingOption(
                          code,
                          props.dropdownOptions
                        )}
                        label={label}
                        isReverseDependentDropDown={
                          !!tags?.find(tag => tag === "ReverseDependentField")
                        }
                        name={code}
                        key={key}
                        placeholder={label}
                        touched={meta.touched}
                        error={meta.error}
                        value={field.value}
                        options={options || []}
                        parentWaitingMessage="Please select parent value(s) to fetch records"
                        emptyOptionsMessage="No records found for given values"
                        masterDataType={masterDataType}
                        tags={tags}
                        onChange={options => {
                          formik.setFieldValue(code, options);
                          if (code === "dataType") {
                            removeDisabledInputValues(
                              { ...formValues, [code]: options },
                              formik.setFieldValue
                            );
                            formik.setFieldValue(
                              "type",
                              inputTypes[options].type
                            );
                          }
                        }}
                        dependencyTree={props.dependencyTree}
                        allValues={form.values}
                        allValuesMandatory={false}
                        isClearable={code !== "dataType"}
                        isSearchable
                        withFeedbackLabel
                        isReadOnly={isReadOnly}
                        disabled={
                          restrictions.disabled ||
                          (code === "masterDataType" &&
                            !dropdownFieldIds.includes(form.values["dataType"]))
                        }
                        hideCodeInDropdownLabel
                        isHideValidations={true}
                        setFieldValue={formik.setFieldValue}
                        shouldUseLocalDropdownOptions={
                          props?.shouldUseLocalDropdownOptions
                        }
                        overwriteSorting={props?.overwriteSorting}
                      />
                    ) : (
                      <ReactSelect
                        field={field}
                        prevState={props?.prevState ? props?.prevState : null}
                        getDropdownSelectedValue={getDropdownSelectedValue}
                        label={label}
                        name={code}
                        placeholder={label}
                        touched={meta.touched}
                        error={meta.error}
                        isReadOnly={isReadOnly}
                        value={
                          getDropdownSelectedValue(
                            options,
                            field.value,
                            "single"
                          ) || ""
                        }
                        options={options}
                        onChange={option => {
                          onChangeDropdown(
                            option,
                            code,
                            formik.setFieldValue,
                            form.values
                          );
                        }}
                        isClearable={
                          !["dataType", "masterDataType"].includes(code)
                        }
                        // isClearable
                        isSearchable
                        withFeedbackLabel
                        {...restrictions}
                        disabled={
                          restrictions?.disabled
                          // ||
                          // (code === 'masterDataType' &&
                          //   !dropdownFieldIds.includes(form.values['dataType']))
                          // Disabling for default value work
                          // || (code === 'defaultValue' && form?.values['defaultValueType'] !== 1)
                        }
                        customers={customers}
                        masterDataType={masterDataType}
                        overwriteSorting={props?.overwriteSorting}
                      />
                    )}

                    {meta.touched && meta.error && (
                      <FieldFeedbackLabel
                        error={meta.error}
                        touched={meta.touched}
                        label={label}
                        type={type}
                        customFeedbackLabel={true}
                        forceShowError={true}
                      />
                    )}
                  </>
                )}
              </Field>
            </div>
          );

        case "multiselect":
          const multiOptions = [
            { label: "Select All", value: "all" },
            ...getDropdownOptions(masterDataType, "", code)
          ];

          let maxLengthBoth =
            maxLength > 0 ? maxLength : fieldsDictionary?.[code]?.maxLength;
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {label ? <label>{label}</label> : null}
                    {isRequired ? (
                      <span className="text-danger"> *</span>
                    ) : null}
                    {description?.length ? (
                      <Overlay popoverContent={description}>
                        <i
                          className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                          }
                        />
                      </Overlay>
                    ) : null}
                    {props.dependencyTree?.[code] ? (
                      <ReactSelectControlled
                        label={label}
                        name={code}
                        placeholder={label}
                        touched={meta.touched}
                        error={
                          meta.error ? (
                            <>
                              <b>{name}</b> is required
                            </>
                          ) : (
                            ""
                          )
                        }
                        value={field.value}
                        isReadOnly={isReadOnly}
                        options={
                          props.dropdownOptions?.[masterDataType]?.entities ||
                          []
                        }
                        parentWaitingMessage="Please select values to fetch records"
                        emptyOptionsMessage="No records found for given values"
                        masterDataType={masterDataType}
                        onChange={options => {
                          formik.setFieldValue(code, options);
                        }}
                        isHideValidations={true}
                        dependencyTree={props.dependencyTree}
                        allValues={form.values}
                        allValuesMandatory={false}
                        isMulti
                        isClearable
                        isSearchable
                        isRequired={isRequired}
                        closeMenuOnSelect={false}
                        withFeedbackLabel
                        {...restrictions}
                        disabled={
                          restrictions.disabled ||
                          (code === "dependentField" &&
                            !dropdownFieldIds.includes(form.values["dataType"]))
                        }
                        hideCodeInDropdownLabel
                        valueAsString={
                          props?.overrideValueAsString !== undefined
                            ? props?.overrideValueAsString
                            : true
                        }
                        setFieldValue={formik.setFieldValue}
                        overwriteSorting={props?.overwriteSorting}
                      />
                    ) : (
                      <ReactSelectDraggable
                        field={field}
                        prevState={
                          props?.prevState
                            ? props?.prevState[field?.name]
                            : null
                        }
                        getDropdownComparisonValue={getDropdownComparisonValue}
                        masterDataType={masterDataType}
                        label={label}
                        name={code}
                        placeholder={label}
                        touched={meta.touched}
                        isRequired={isRequired}
                        meta={meta}
                        key={
                          getDropdownSelectedValue(
                            multiOptions,
                            field.value,
                            "multi",
                            valueAsString
                          ) || ""
                        }
                        error={
                          isRequired &&
                          !getDropdownSelectedValue(
                            multiOptions,
                            field.value,
                            "multi",
                            valueAsString
                          )?.length
                            ? `${name} is required`
                            : null
                        }
                        onError={formik.setFieldError}
                        value={
                          getDropdownSelectedValue(
                            multiOptions,
                            field.value,
                            "multi",
                            valueAsString
                          ) || ""
                        }
                        options={
                          field?.value?.length === multiOptions?.length - 1
                            ? multiOptions?.filter(
                                data => data?.value !== "all"
                              )
                            : multiOptions
                        }
                        onChange={option => {
                          let isAllOption = option?.some(
                            option => option?.value === "all"
                          );
                          if (
                            maxLengthBoth > 0
                              ? getDropdownSelectedValue(
                                  multiOptions,
                                  option,
                                  "multi",
                                  valueAsString
                                )?.length > maxLengthBoth ||
                                (isAllOption &&
                                  multiOptions?.length - 1 > maxLengthBoth)
                              : false
                          ) {
                            warningNotification(
                              `You can only select upto ${maxLengthBoth} options`
                            );
                          } else {
                            if (code === "subFields") {
                              let tempComputations = { ...props?.computations };
                              let tempUniqueSubFields = [];
                              Object.keys(props?.computations).forEach(key => {
                                if (
                                  !(option || [])?.find(
                                    option => option?.value === key
                                  )
                                )
                                  delete tempComputations[key];
                              });
                              props.setComputations(tempComputations);
                              for (let i = 0; i < option.length; i++) {
                                if (
                                  props?.uniqueSubFields?.includes(
                                    option[i].value
                                  )
                                ) {
                                  tempUniqueSubFields.push(option[i].value);
                                }
                              }
                              props.setUniqueSubFields &&
                                props.setUniqueSubFields(tempUniqueSubFields);
                            }
                            onChangeDropdown(
                              isAllOption
                                ? (multiOptions || [])?.filter(
                                    data => data?.value !== "all"
                                  )
                                : option || "",
                              code,
                              formik.setFieldValue,
                              form.values,
                              valueAsString
                            );
                          }
                        }}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          let newArray = arrayMove(
                            getDropdownSelectedValue(
                              multiOptions,
                              field.value,
                              "multi",
                              valueAsString
                            ) || "",
                            oldIndex,
                            newIndex
                          );
                          onChangeDropdown(
                            newArray,
                            code,
                            formik.setFieldValue,
                            form.values,
                            valueAsString
                          );
                        }}
                        isMulti
                        isReadOnly={isReadOnly}
                        isClearable
                        isSearchable
                        closeMenuOnSelect={false}
                        withFeedbackLabel
                        openComputationModal={props.openComputationModal}
                        {...restrictions}
                        disabled={
                          restrictions.disabled ||
                          (code === "dependentField" &&
                            !dropdownFieldIds.includes(
                              form.values["dataType"]
                            )) ||
                          (code === "subFields" &&
                            !dropdownListIds.includes(form.values["dataType"]))
                        }
                        overwriteSorting={props?.overwriteSorting}
                      />
                    )}
                  </>
                )}
              </Field>
            </div>
          );

        case "dualListBox":
          const listOptions = getDropdownOptions(
            masterDataType,
            "dualListBox",
            code
          );
          return (
            <>
              <div
                className="col-lg-12 mt-8 mb-3"
                key={key}
                hidden={restrictions?.hidden}
              >
                <Field name={code}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // { values, setXXX, handleXXX, dirty, isValid etc }
                    meta // {touched, error}
                  }) => (
                    <>
                      {label && <label>{label} To Include</label>}
                      {isRequired ? (
                        <span className="text-danger"> *</span>
                      ) : null}
                      {meta.error && (
                        <h6 className="dual-list-box-required">
                          {label} are required
                        </h6>
                      )}
                      {description?.length ? (
                        <Overlay popoverContent={description}>
                          <i
                            className={
                              "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                            }
                          />
                        </Overlay>
                      ) : null}
                      <DualListBox
                        // showHeaderLabels
                        showOrderButtons
                        // simpleValue={false}    // onChange will return [{label, value}] if false, else [value1, value2] - true by default
                        canFilter
                        preserveSelectOrder
                        prevState={props?.prevState ? props?.prevState : null}
                        showNoOptionsText
                        label={label}
                        name={code}
                        touched={meta.touched}
                        error={meta.error}
                        options={listOptions || []}
                        selected={field.value}
                        onChange={selected => {
                          formik.setFieldValue(code, selected || null);
                        }}
                        description={description}
                        showDescription={description?.length}
                        {...restrictions}
                      />
                    </>
                  )}
                </Field>
              </div>
            </>
          );

        case "checkbox":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <Switch
                    classNames={"checkbox-primary checkbox-lg"}
                    field={field}
                    form={form}
                    meta={meta}
                    onError={formik.setFieldError}
                    isRequired={isRequired}
                    isReadOnly={isReadOnly}
                    label={label}
                    name={code}
                    type={inputType}
                    placeholder={label || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        case "switch":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <Switch
                    classNames={"checkbox-primary checkbox-lg"}
                    field={field}
                    form={form}
                    meta={meta}
                    label={label}
                    isReadOnly={isReadOnly}
                    onError={formik.setFieldError}
                    isRequired={isRequired}
                    name={code}
                    type={inputType}
                    placeholder={label || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        case "datePicker":
          return (
            <div
              code={code}
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DatePickerField
                    field={{ ...field }}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    tags={tags}
                    form={form}
                    meta={meta}
                    label={label}
                    name={code}
                    type={type}
                    placeholder={label || code}
                    description={description}
                    showDescription={description?.length}
                    withFeedbackLabel={true}
                    {...restrictions}
                    onChange={field.onChange}
                    dropdownMode={"select"}
                    dateFormat="dd-MMM-yyyy"
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "dateTimePicker":
          return (
            <div
              code={code}
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DatePickerField
                    field={{ ...field }}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    tags={tags}
                    form={form}
                    meta={meta}
                    label={label}
                    name={code}
                    type={type}
                    placeholder={label || code}
                    description={description}
                    showDescription={description?.length}
                    withFeedbackLabel={true}
                    {...restrictions}
                    onChange={field.onChange}
                    dropdownMode={"select"}
                    showTimeSelect
                    dateFormat="hh:mm aa, dd-MMMM-yyyy"
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "colorPicker":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              code={code}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code} validate={validateColorCode}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <ColorPicker
                    field={{ ...field }}
                    form={form}
                    meta={meta}
                    label={label}
                    name={code}
                    withFeedbackLabel={true}
                    onChange={field.onChange}
                    {...restrictions}
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "iconPicker":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              code={code}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <IconPickerField
                    field={{ ...field }}
                    form={form}
                    meta={meta}
                    label={label}
                    name={code}
                    withFeedbackLabel={true}
                    onChange={field.onChange}
                    {...restrictions}
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "creatableSelect":
          const dropdownOptionCreatable = getDropdownOptions(
            masterDataType,
            "",
            code
          );

          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              code={code}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {label ? <label>{label}</label> : null}
                    {isRequired ? (
                      <span className="text-danger"> *</span>
                    ) : null}
                    {description?.length ? (
                      <Overlay popoverContent={description}>
                        <i
                          className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                          }
                        />
                      </Overlay>
                    ) : null}
                    <ReactCreatableSelect
                      field={{ ...field }}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      label={label}
                      name={code}
                      options={dropdownOptionCreatable}
                      placeholder={label}
                      description={description}
                      showDescription={description?.length}
                      touched={meta.touched}
                      error={meta.error}
                      value={getCreatedSelectValue(
                        field?.value || defaultValue || [],
                        dropdownOptionCreatable,
                        isSingle
                      )}
                      onChange={option => {
                        if (
                          maxLength > 0
                            ? getCreatedSelectValue(
                                option || [],
                                dropdownOptionCreatable,
                                isSingle
                              )?.length > maxLength
                            : false
                        ) {
                          warningNotification(
                            `You can only select upto ${maxLength} options`
                          );
                        } else {
                          onChangeDropdown(
                            option || "",
                            code,
                            formik.setFieldValue,
                            form.values,
                            false
                          );
                        }
                      }}
                      isMulti={!isSingle}
                      isClearable
                      // isValidNewOption={(inputValue, selectValue, selectOptions, accessor) => {
                      //   formik.errors[code] = "asdasdas";
                      //   return false;
                      // }}
                      noOptionsMessage={() => null}
                      isSearchable
                      closeMenuOnSelect={true}
                      formatCreateLabel={inputValue => `Create ${inputValue}`}
                      withFeedbackLabel
                      {...restrictions}
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "fileInput":
          return (
            <div
              code={code}
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DocumentUploadInput
                    label={label}
                    field={field}
                    form={form}
                    meta={meta}
                    productCode={props?.documentUploadBucketCode || code}
                    code={code}
                    description={description}
                    showDescription={description?.length}
                    disabled={restrictions?.disabled}
                    isReadOnly={isReadOnly}
                    isRequired={isRequired}
                    identifier={
                      props?.identifier ||
                      props?.documentUploadBucketCode ||
                      code
                    }
                    touched={meta.touched}
                    error={meta.error}
                  />
                )}
              </Field>
            </div>
          );

        case "list":
          return (
            <div
              code={code}
              className="col-lg-12 mb-3"
              key={key}
              hidden={restrictions?.hidden || field.isHidden}
            >
              <Field name={`${code}`} validateOnMount={true}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {/*
                      grid rakhenge
                      usko activeGridFormData mein {index, data} ye provide karenge
                      onSubmit hum array pakrenge, usmein index dhundenge,
                      index update kar k setFormikField kardenge
                    */}
                    <ListFieldGrid
                      field={{ ...field }}
                      fieldCode={code}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      isRequired={isRequired}
                      tags={tags}
                      stageConditions={[]}
                      gridName={label}
                      meta={meta}
                      errors={formik.errors[code]}
                      subFields={subFields || []}
                      mode={props.gridMode || viewType}
                      isValidating={false}
                      data={field.value || []}
                      identifier={props?.identifier}
                      productName={props?.productName}
                      formData={form.values}
                      keyField={"id"}
                      shouldHideActionFormatter={
                        props.shouldHideActionFormatter
                      }
                      columns={(subFields || [])
                        .map(fieldCode =>
                          (
                            detailedFieldsConfig ||
                            fieldsConfig ||
                            props.fieldsList
                          )?.find(field => field?.code === fieldCode)
                        )
                        ?.filter(fld => !fld?.hidden && !fld?.isHidden)
                        ?.map(fld => ({
                          key: fld?.code,
                          dataField: fld?.code,
                          text: `${fld?.name}`,
                          sort: false,
                          headerFormatter: (col, colIndex, components) => {
                            return (
                              <span>
                                {col.text}{" "}
                                {col?.formatExtraData?.parameters
                                  ?.isRequired ? (
                                  <span className="grid-required-field">
                                    {" "}
                                    *
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            );
                          },
                          formatter: ApplicationDynamicFormatter,
                          formatExtraData: {
                            parameters: {
                              isRequired: fld?.isRequired,
                              identifier: fld?.code,
                              allRecords: {
                                ...(recordsState || {}),
                                ...(fieldsDictionary || {}),
                                ...parseDropdownOptionsToRecords(
                                  props.dropdownOptions
                                )
                              },
                              field: fld,
                              masterDataType: fld?.masterDataType,
                              dataType: fld?.dataType,
                              customers
                            }
                          }
                        }))}
                      onChangeGrid={gridData => {
                        // formik.setFormData(prevData => ({...prevData, fields: {...prevData.fields, ...fields, [code]: gridData}, gridFields: {...gridFieldsData}}))
                        formik.setFieldValue(code, gridData);
                        formik.setFieldValue("gridFields", {
                          ...(form.values.gridFields || {}),
                          [code]: gridData
                        });
                        // formik.setFormData(prevData => ({...prevData, fields: {...prevData.fields, ...fields, [code]: gridData}, gridFields: {...gridFieldsData}}))
                      }}
                      allRecords={{
                        ...(fieldsDictionary || {}),
                        ...parseDropdownOptionsToRecords(props.dropdownOptions)
                      }}
                      hideCodeInDropdownLabel={props.hideCodeInDropdownLabel}
                      customers={customers}
                      {...restrictions}
                    ></ListFieldGrid>
                  </>
                )}
              </Field>
            </div>
          );

        case "richTextBox":
          return (
            <div
              code={code}
              key={key}
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                "col-lg-12 mb-3"
              }
              hidden={restrictions?.hidden || isHidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <RichTextBox
                    prevState={props?.prevState ? props?.prevState : null}
                    classes={code}
                    field={{ ...field }}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    tags={tags}
                    meta={meta}
                    form={form}
                    label={label}
                    name={code}
                    type={inputType}
                    placeholder={label || code}
                    showDescription={description?.length}
                    description={description}
                    {...restrictions}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    onChange={field.onChange}
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "conditionBuilder":
          return (
            <div
              code={code}
              className="col-lg-12 mb-3 mt-3"
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <>
                    <QueryBuilder
                      heading={label}
                      isHeading
                      isRequired={isRequired}
                      key={"qb" + code}
                      fieldsList={props?.allFields}
                      dropdownOptions={props.dropdownOptions}
                      expression={
                        field.value?.representations?.[0]?.representationValue
                      }
                      queryValue={field.value?.expressionTree}
                      onChange={props =>
                        onChangeQueryBuilder(
                          props,
                          code,
                          representations,
                          formik
                        )
                      }
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "textArea":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                "col-lg-12 mb-3"
              }
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <TextArea
                    field={field}
                    form={form}
                    meta={meta}
                    label={label}
                    name={code}
                    type={inputType}
                    placeholder={"Enter " + (label || code)}
                    withFeedbackLabel={true}
                    {...restrictions}
                    disabled={restrictions.disabled}
                    onChange={field.onChange}
                    required={isRequired}
                  />
                )}
              </Field>
            </div>
          );

        case "mathematicalExpression":
          return (
            <div
              className={
                (layout && fieldLayoutTitles[layout] + " mb-3") ||
                props.layout ||
                "col-lg-4 mb-3"
              }
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <MathematicalExpression
                    field={field}
                    form={form}
                    meta={meta}
                    onError={formik.setFieldError}
                    isRequired={isRequired}
                    isReadOnly={isReadOnly}
                    label={label}
                    name={code}
                    type={inputType}
                    placeholder={label || code}
                    withFeedbackLabel={true}
                    setFieldValue={formik.setFieldValue}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        default:
          return null;
      }
    });

    // will only come here when skeletons are not to be shown AND fields have not been calculated i.e
    // only on initial render!
    setRenderableFields(fieldsToRender);
    return fieldsToRender;
  };

  const onClickAdd = values => {
    Object.keys(values).forEach(key => {
      if (!values[key] && defaultValuesList[key]) {
        values[key] = defaultValuesList[key];
      }
    });
    props.saveField(values);
  };

  const onClickReset = resetFormCallback => {
    resetFormCallback(props.fieldsValues);
  };

  const onClickSave = formik => {
    props.saveField(formik.values);
  };
  const onSubmitForm = async (
    {
      values,
      errors,
      setFieldTouched,
      setFieldError,
      touched,
      validateForm,
      setTouched
    },
    submitCallback
  ) => {
    const formErrorsState = await validateForm(values);
    const fields = fieldsConfiguration.fields;
    let formValidity = !Object.keys(formErrorsState).length;
    fields.forEach(field => {
      if (values[field.code] === null || values[field.code] === undefined) {
        // if(field.isRequired && field.type !== "number") {
        if (field.isRequired) {
          formValidity = false;
          setTimeout(() => setFieldTouched(field.code, true));
          setFieldError(field.code, `${field.name} is required!`);
        } else {
          setFieldTouched(field.code, true);
          setFieldError(field.code, null);
        }
      } else {
        if (formErrorsState[field.code]) {
          setFieldTouched(field.code, true);
        }
      }
    });
    if (formValidity) {
      submitCallback(removeWhitespaces(values));
    } else {
      errorNotification(
        "Please fill out all required fields before continuing"
      );
    }
  };

  const remountFormValidations = debounce(formik => {
    const { touched, errors } = formik || {};
    if (!Object.keys(touched || {}).length && Object.keys(errors || {}).length)
      formik.handleSubmit();
  }, 500);

  const setFormValidationFalse = () => {
    setIsValidatingForm(false);
  };

  const validateFormDataAgainstConditions = (
    conditions,
    conditionsStatus,
    values,
    setFieldValue,
    setFieldTouched
  ) => {
    let updatedValues = { ...values };
    let allFields = [...props?.fieldsList];
    let fieldsStatus = { ...fieldsOriginalState };

    let newValues = {};
    // validate each of the given conditions against the data available
    let conditionsStatusTemp = { ...conditionsStatus };
    let changedConditions = [];
    conditions.forEach(cond => {
      let jsonQuery = JSON.parse(findJSONQuery(cond));
      const validationStatus = validateJSONLogic(jsonQuery, updatedValues);
      if (
        (validationStatus && conditionsStatus[cond.code] === undefined) ||
        (conditionsStatus[cond.code] !== undefined &&
          conditionsStatus[cond.code] !== validationStatus) ||
        !setFieldValue
      ) {
        if (!isValidatingForm) setIsValidatingForm(true);
        changedConditions.push({ ...cond, validationStatus });
      }
    });

    changedConditions = changedConditions.sort(
      (x, y) => x.validationStatus - y.validationStatus
    );
    changedConditions.forEach(cond => {
      let {
        updatedValues: tempUpdatedValues,
        updatedFields: tempAllFields,
        updatedFieldsState: tempFieldsStatus
      } = enforceOutcomes(
        allFields,
        fieldsStatus,
        cond.validationStatus,
        cond.outcomes || [],
        setFieldTouched
      );

      newValues = { ...newValues, ...tempUpdatedValues };
      allFields = [...tempAllFields];
      fieldsStatus = { ...fieldsStatus, ...tempFieldsStatus };
      conditionsStatusTemp[cond.code] = cond.validationStatus;
    });

    if (changedConditions.length) {
      setConditionsStatus(conditionsStatusTemp);
      setFieldsState(fieldsStatus);
      props.setFieldsList(allFields);

      if (setFieldValue) {
        Object.keys(newValues).forEach(key => {
          setFieldValue(key, newValues[key]);
        });
      }
    }
    setFormValidationFalse();

    if (setFieldValue) {
      return null;
    } else {
      return newValues;
    }
  };

  const enforceOutcomes = (
    allFields,
    fieldsOriginalState,
    validationStatus,
    outcomes = [],
    setFieldTouched
  ) => {
    let allStageFieldsTemp = [...allFields];
    let tempFieldsState = { ...fieldsOriginalState };
    let updatedValues = {};

    outcomes.forEach(outcome => {
      let outcomeData = removeEmptyParameters(outcome);
      const outcomeType = (dropDownOptions?.outcomeTypes || []).find(
        item => item.code === outcomeData?.type
      )?.name;
      switch (outcomeType) {
        case "Field": {
          delete outcomeData.code;
          delete outcomeData.priority;

          allFields.forEach((fld, index) => {
            if (fld.code === outcome.code) {
              if (!tempFieldsState[outcome.code]) {
                let fieldsOriginalData = {};
                Object.keys(outcomeData).forEach(key => {
                  fieldsOriginalData[key] = fld[key];
                });
                tempFieldsState[outcome.code] = fieldsOriginalData;
              }
              // let updatedFieldData = !validationStatus ? {...(tempFieldsState[outcome.code] || {})} : {...outcomeData};
              let updatedFieldData = {};
              if (validationStatus) {
                updatedFieldData = { ...outcomeData };
                tempFieldsState[outcome.code] = {
                  ...(tempFieldsState[outcome.code] || {}),
                  ...(outcomeData || {})
                };
                if (outcome.isHidden || outcome.hidden) {
                  setFieldTouched(outcome.code, false);
                }
              } else {
                let originalField = clonedFieldList.find(
                  field => field.code === outcome.code
                );
                updatedFieldData = { ...(originalField || {}) };
                // updatedFieldData = {...(tempFieldsState[outcome.code] || {})}
              }
              allStageFieldsTemp[index] = {
                ...allFields[index],
                ...updatedFieldData
              };
              if (
                updatedFieldData?.isReadOnly &&
                !outcomeData?.value &&
                !validationStatus
              ) {
                updatedValues[fld.code] = null;
              }
              if (validationStatus && outcomeData?.value)
                updatedValues[fld.code] = getOutcomeValueInDesiredFormat(
                  fld.dataType,
                  outcomeData?.value
                );

              if (
                (inputTypes.find(
                  input => input?.id === allStageFieldsTemp[index]?.dataType
                )?.type === "checkbox" ||
                  inputTypes.find(
                    input => input?.id === allStageFieldsTemp[index]?.dataType
                  )?.type === "switch") &&
                !validationStatus
              ) {
                updatedValues[fld.code] = false;
              }
            }
          });
          return;
        }

        default:
          return;
      }
    });
    return {
      updatedFields: allStageFieldsTemp,
      updatedFieldsState: tempFieldsState,
      updatedValues
    };
  };

  // render ()
  return renderableSkeletons?.length ? (
    <div className="form-group row">{renderableSkeletons}</div>
  ) : (
    <Formik
      initialValues={formValues}
      validationSchema={fieldsConfiguration?.validationSchema || {}}
      onSubmit={values => onClickAdd(values)}
      enableReinitialize={Boolean(props.enableReinitialize)}
    >
      {formik => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          remountFormValidations(formik);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        // eslint-disable-next-line react-hooks/rules-of-hooks

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (props?.conditionsForModal?.length > 0) {
            validateFormDataAgainstConditions(
              props.conditionsForModal || [],
              conditionsStatus,
              formik.values,
              formik.setFieldValue,
              formik.setFieldTouched
            );
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          const { values, errors } = formik || {};

          if (values) {
            for (const [key, value] of Object.entries(values)) {
              if (typeof props.onChangeMethods?.[key] === "function") {
                let callback = props.onChangeMethods?.[key];

                if (formValues[key] !== value) {
                  callback(
                    key,
                    value,
                    Boolean(Object.keys(errors || {}).length),
                    values
                  );

                  if (typeof props.onChangeOutcomes?.[key] === "object") {
                    let outcomes = props.onChangeOutcomes?.[key];

                    for (const [outcomeKey, outcomeValue] of Object.entries(
                      outcomes
                    )) {
                      formik.setFieldValue(outcomeKey, outcomeValue);
                    }
                  }
                }
              }
            }
          }

          if (props?.conditionsForModal?.length > 0) {
            validateFormDataAgainstConditions(
              props?.conditionsForModal || [],
              conditionsStatus,
              formik.values,
              formik.setFieldValue,
              formik.setFieldTouched
            );

            // let updatedFormErrorState = async () => {
            //
            //   const formErrorsState = await validateForm(values);
            //   console.log({formErrorsState})
            //
            // };
            // console.log(updatedFormErrorState())
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [formik.values]);
        // eslint-disable-next-line react-hooks/rules-of-hooks

        return (
          <Form
            className="form form-label-right"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-group row">
              {getRenderableFields(
                formik,
                fieldsConfiguration.fields,
                props.showSkeleton
              )}
              {props.title === "user" ? props.children : null}
            </div>
            {props.title !== "user" ? props.children : null}
            <button
              type="button"
              style={{ display: "none" }}
              onClick={() => onClickReset(formik.resetForm)}
              ref={props.resetBtnRef}
            ></button>
            <button
              type="button"
              style={{ display: "none" }}
              hidden={viewType === "view"}
              onClick={() => {
                onClickSave(formik);
              }}
              ref={props.saveButtonRef}
            ></button>
            <button
              type="button"
              style={{ display: "none" }}
              ref={props.btnRef}
              hidden={viewType === "view"}
              onClick={() => onSubmitForm(formik, formik.handleSubmit)}
            ></button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditViewForm;
