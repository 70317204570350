import React, { Children, useEffect, useState } from "react";
import Select, { components, createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import { Overlay } from "./Overlay";
import { sortDropdownsOptions } from "../../../_helpers";
import VirtualList from "react-tiny-virtual-list";
import { fieldLayoutTitles, fieldLayoutOptions } from "../../grids/UIHelpers";

const DefaultItemHeight = 40;

const animatedComponents = makeAnimated();

export function ReactSelect(props) {
  const {
    label,
    disabled,
    isReadOnly,
    value,
    isRequired,
    meta,
    field,
    styles,
    form,
    setCustomerModalMode,
    getDropdownSelectedValue,
    customers,
    isModalOpen,
    setModalOpen,
    modalCurrentIndex,
    gridData = {},
    overwriteSorting = false,
    widget,
    setFormValues,
    masterDataType
  } = props;

  const [isErrorPropagated, setIsErrorPropagated] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!isRequired) return;

    if (
      (!value?.toString()?.length || value === "undefined") &&
      isRequired &&
      !isErrorPropagated
    ) {
      setIsErrorPropagated(true);
      props.onError(props.name, label + " is required.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isRequired]);

  useEffect(() => {
    if (props?.options?.length === 1 && props?.form) {
      props.form.setFieldValue(props.classes, props?.options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.options?.length]);

  useEffect(() => {
    if (customers?.length) {
      let tempCustomerList = [];
      let tempSelectedCustomer = {};

      (customers || []).forEach(item => {
        if (item.cnic === field?.value) {
          tempSelectedCustomer = {
            label: item.name,
            value: item.cnic
          };
        }

        tempCustomerList.push({
          label: item.name,
          value: item.cnic
        });
      });

      setSelectedCustomer(tempSelectedCustomer);
      // sortByAlphabeticalOrder(tempCustomerList, "label")
      setOptionsData(tempCustomerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    let customerIndexForGrid;
    let customerTag =
      props.tags?.find(tag => tag?.code === "Customers") !== undefined;

    if (modalCurrentIndex === -1) {
      customerIndexForGrid = gridData?.length || Object.keys(gridData)?.length;
    } else {
      customerIndexForGrid = modalCurrentIndex;
    }

    if (customerTag && customers?.length && Array.isArray(gridData)) {
      setSelectedCustomer({
        label: customers[customerIndexForGrid]?.name,
        value: customers[customerIndexForGrid]?.cnic
      });
      setSelectedValue(customers[customerIndexForGrid]?.cnic);

      form.setFieldValue(props.classes, customers[customerIndexForGrid]?.cnic);

      if (widget && setFormValues) {
        setFormValues(prevData => ({
          ...prevData,
          [widget.dateOfBirth]: customers[customerIndexForGrid]?.dateOfBirth,
          [widget.mobileNumber]: customers[customerIndexForGrid]?.mobileNumber,
          [props.name]: customers[customerIndexForGrid]?.cnic
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, gridData?.length, field?.value, modalCurrentIndex]);

  const handleChange = selected => {
    setSelectedValue(selected);
    form.setFieldValue(props.classes, selected?.value);
  };

  const openCustomerModal = () => {
    if (isModalOpen) {
      setModalOpen(false);
    }
    setCustomerModalMode("form");
  };

  const handleKeyDown = e => {
    if (e.key === "ArrowDown" && e.keyCode === 40) {
      if (index === Number(props.options?.length || 0) - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
    if (e.key === "ArrowUp" && e.keyCode === 38) {
      if (index === 0) {
        setIndex(Number(props.options?.length || 0) - 1);
      } else {
        setIndex(index - 1);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Handle the arrow key events for scrolling
    const handleKeyDownEvent = e => {
      handleKeyDown(e);
    };

    window.addEventListener("keydown", handleKeyDownEvent);

    return () => {
      window.removeEventListener("keydown", handleKeyDownEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, props.options]);

  const findObjectIndex = (array, object) => {
    return array.findIndex(item => item?.value === object?.value);
  };

  const Menu = props => {
    return (
      <components.Menu {...props}>
        <div>
          <div className="select-options">{props.children}</div>
          <div className="text-center">
            <button
              className="btn btn-xs btn-success mb-2"
              onClick={() => {
                props.selectProps.onClick();
              }}
            >
              Add Customer
            </button>
          </div>
        </div>
      </components.Menu>
    );
  };

  const style = {
    ...styles,
    control: base => ({
      ...base,
      border: "2px solid #3699FF"
    }),
    menu: base => ({
      ...base,
      zIndex: 2
    })
  };

  const customStyles = {
    ...styles,
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2 // Set your desired zIndex value here
    })
  };

  const Option = props => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    const colValue = newProps?.data?.value;
    const fieldValue = field?.value;

    return (
      <>
        <components.Option
          className={"overflow-prevent-react-select"}
          {...newProps}
        >
          {masterDataType === "fieldLayout" ? (
            <div className="row">
              {fieldLayoutOptions?.map(({ value, length }) => {
                return (
                  <>
                    {colValue === value ? (
                      <>
                        {new Array(length).fill({}).map(() => {
                          return (
                            <div className={fieldLayoutTitles[value]}>
                              <div
                                className={`h-30px bg-${
                                  fieldValue === colValue
                                    ? "light-success"
                                    : "success"
                                } rounded w-100`}
                              ></div>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </>
                );
              })}
            </div>
          ) : (
            <>
              {" "}
              <span>{newProps?.data?.label}</span>
              {newProps?.data?.tooltip ? (
                <Overlay popoverContent={newProps?.data?.tooltip}>
                  <i
                    className={
                      "fas fa-info-circle icon-nm text-hover-primary mr-n2  float-right"
                    }
                  />
                </Overlay>
              ) : null}
            </>
          )}
        </components.Option>
      </>
    );
  };

  const renderItem = props => {
    const { children } = props;
    if (Array.isArray(children)) {
      return (
        <li
          style={props.style}
          key={props.index}
          title={children?.[props.index]?.props?.children || ""}
        >
          {children?.[props.index] || ""}
        </li>
      );
    }
    return (
      <li
        key={props.index}
        className="react-virtualized-menu-placeholder"
        title={children.props?.children || ""}
      >
        {children.props?.children || ""}
      </li>
    );
  };

  const CustomMenuList = props => {
    // const { options, children, maxHeight, getValue } = props;
    const { children, maxHeight } = props;

    // const [value] = getValue();
    // const initialOffset = options.indexOf(value) * DefaultItemHeight;
    const childrenOptions = Children.toArray(children);
    const wrapperHeight =
      maxHeight < childrenOptions.length * DefaultItemHeight
        ? maxHeight
        : childrenOptions.length * DefaultItemHeight;

    return (
      <span className="react-virtualized-list-wrapper">
        <VirtualList
          width="100%"
          height={wrapperHeight + 6}
          // scrollOffset={initialOffset}
          itemCount={childrenOptions.length}
          scrollToIndex={index}
          scrollToAlignment="auto"
          scrollDirection="vertical"
          itemSize={DefaultItemHeight}
          renderItem={innerProps => renderItem({ ...props, ...innerProps })}
        />
      </span>
    );
  };

  const IsValuesDifferent = (previousValue, newValue) => {
    if (!previousValue && !newValue) return false;

    if (!previousValue && newValue) return true;

    if (previousValue && !newValue) return true;

    if (previousValue !== newValue) return true;

    return false;
  };
  const customOptionLabel = ({ label, value }) => {
    return (
      <label className="layout-label">
        <div className="row w-300px">
          {fieldLayoutOptions?.map(item => {
            return (
              <>
                {value === item?.value ? (
                  <>
                    {new Array(item?.length).fill({}).map(() => {
                      return (
                        <div className={fieldLayoutTitles[value]}>
                          <div
                            className={`h-25px bg-success rounded mt-2 w-100`}
                          ></div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </>
            );
          })}
        </div>
      </label>
    );
  };

  return (
    <>
      {props.tags?.find(tag => tag?.code === "Customers") !== undefined ? (
        <>
          <Select
            {...props}
            options={optionsData}
            value={getDropdownSelectedValue(
              optionsData,
              field?.value || selectedValue || selectedCustomer,
              "single"
            )}
            captureMenuScroll={false}
            isDisabled={isReadOnly || disabled || Array.isArray(gridData)}
            components={{
              animatedComponents,
              Menu,
              Option,
              MenuList: CustomMenuList
            }}
            classNamePrefix="custom-select"
            filterOption={createFilter({ ignoreAccents: false })}
            onClick={openCustomerModal}
            onChange={handleChange}
          />
        </>
      ) : (
        <>
          {props?.prevState &&
          IsValuesDifferent(props.prevState[field?.name], field?.value) ? (
            <span className="field-modified-tag">Modified</span>
          ) : null}

          {isReadOnly || disabled ? (
            <div
              title={props.value?.label || ""}
              style={{
                width: "95%",
                height: "38px",
                position: "absolute"
                // zIndex: 1
              }}
            ></div>
          ) : null}

          <Select
            {...props}
            touched={true}
            value={
              value
                ? props.options[
                    Number(findObjectIndex(props.options || [], value))
                  ]
                : ""
            }
            styles={
              props?.prevState &&
              IsValuesDifferent(props.prevState[field?.name], field.value)
                ? style
                : customStyles
            }
            options={
              overwriteSorting
                ? props.options
                : sortDropdownsOptions(props.options)
            }
            isDisabled={isReadOnly || disabled}
            captureMenuScroll={false}
            components={{
              animatedComponents,
              Option,
              MenuList: CustomMenuList
            }}
            // onKeyDown={handleKeyDown}
            onMenuOpen={() => {
              value !== undefined
                ? setIndex(Number(findObjectIndex(props.options, value)))
                : setIndex(0);
            }}
            classNamePrefix="custom-select"
            filterOption={createFilter({ ignoreAccents: false })}
            formatOptionLabel={
              masterDataType === "fieldLayout" && customOptionLabel
            }
          />
          {!props.isHideValidations ? (
            <>
              {(!props.value?.toString()?.length ||
                props.value === "undefined") &&
              props.isRequired ? (
                <div className="invalid-feedback d-block">
                  {label} is required
                </div>
              ) : meta?.error &&
                !(
                  (!props.value?.toString()?.length ||
                    props.value === "undefined") &&
                  props.isRequired
                ) ? (
                <div className="invalid-feedback d-block">{meta.error}</div>
              ) : null}
            </>
          ) : null}

          {props?.prevState &&
          IsValuesDifferent(props.prevState[field?.name], field.value) ? (
            <p className="pt-1 mb-0">
              <span style={{ fontWeight: "bolder" }}>Before: </span>
              <span>
                {props?.getDropdownSelectedValue(
                  sortDropdownsOptions(props.options),
                  props?.prevState[field?.name],
                  "single"
                )?.label || <i style={{ fontSize: "13px" }}>No Data</i>}
              </span>
            </p>
          ) : null}
          {/*{*/}
          {/*  meta?.error && !((!props.value?.toString()?.length || props.value === "undefined") && props.isRequired) ? <div className="feedback text-danger">{meta.error}</div> : null*/}
          {/*}*/}
        </>
      )}
    </>
  );
}
